import { db } from "@/core";
import type { Employee } from "@msuite/katana";
import { useDocument } from "@msuite/picasso";
import type { FC } from "react";
import { AssignLineTag } from "./assing-line-tag";

/** Props Interface */
interface AssignLineEmployeeProps {
	employeeId: string;
}

export const AssignLineEmployee: FC<AssignLineEmployeeProps> = ({ employeeId }) => {
	/** Hooks */
	const { data: employee } = useDocument<Employee>(db, {
		path: `mitarbeiter/${employeeId}`,
		subscribe: false,
	});

	/** Render */
	return (
		<AssignLineTag
			assignName={employee ? `${employee?.vorname} ${employee.nachname}` : "Wird geladen..."}
		/>
	);
};
