import { ConstructionSiteCard } from "@/components/card";
import { ax } from "@/core";
import {
	searchify,
	type ConstructionSite,
	type IGetConstructionSitesByWorkspaceApiResponse,
} from "@msuite/katana";
import { VStack, useApi } from "@msuite/picasso";
import { useMemo, useState, type FC } from "react";
import { ExistingSearchBoxInput } from "./existing-search-box-input";

/** Props Interface */
interface ExistingSearchBoxProps {
	onSelect: (constructionSite: ConstructionSite) => void;
	renderId: string;
}

export const ExistingSearchBox: FC<ExistingSearchBoxProps> = ({ onSelect, renderId }) => {
	/** State */
	const [searchValue, setSearchValue] = useState<string>("");

	/** Hooks */
	const { data } = useApi<IGetConstructionSitesByWorkspaceApiResponse>(ax, {
		url: "/v2/construction-sites/all",
		dependencies: ["construction-sites", "all", "workspaces", renderId],
	});

	const filteredConstructionSites = useMemo(() => {
		if (searchValue?.length < 3) return [];

		return searchify(data?.constructionSites ?? [], searchValue.replaceAll(" ", ""));
	}, [searchValue, data]);

	/** Functions */
	function handleOnSelect(constructionSite: ConstructionSite) {
		try {
			onSelect(constructionSite);
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnChange(value: string) {
		setSearchValue(value);
	}

	/** Render */
	return (
		<VStack spacing={4}>
			<ExistingSearchBoxInput onChange={handleOnChange} />
			{filteredConstructionSites.map((constructionSite) => (
				<ConstructionSiteCard
					key={constructionSite.id}
					onClick={() => handleOnSelect(constructionSite)}
					subscribe={false}
					constructionSiteId={constructionSite?.id}
					contextMenuType="none"
					modules={["main-information"]}
					onResolveMergeIssue={undefined}
				/>
			))}
		</VStack>
	);
};
