import { ax, storage } from "@/core";
import type { ConstructionSiteDocument } from "@msuite/katana";
import {
	ButtonGroup,
	HStack,
	IconButton,
	Text,
	useAsyncModal,
	useUIContext,
} from "@msuite/picasso";
import { getDownloadURL, ref } from "firebase/storage";
import { type FC, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { TbExternalLink } from "react-icons/tb";
import { useParams } from "react-router-dom";

/** Props Interface */
interface RowActionsProps {
	document: ConstructionSiteDocument;
}

export const RowActions: FC<RowActionsProps> = ({ document }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const projectRequestId = useParams().requestId;

	/** Context */
	const { colors } = useUIContext();
	const asyncModal = useAsyncModal();

	/** Functions */
	async function onOpen() {
		const downloadUrl = await getDownloadURL(ref(storage, document.file_path));
		window.open(downloadUrl, "_blank");
	}

	async function onDelete() {
		try {
			setIsLoading(true);
			if (!document?.id) return;
			const isConfirmed = await asyncModal({
				headerText: "Dokument löschen",
				bodyText: `Möchten Sie das Dokument "${document.file_name}" wirklich löschen?`,
				confirmButtonText: "Löschen",
			});
			if (!isConfirmed) return;
			await ax.delete(`/v2/project-requests/${projectRequestId}/documents/${document.id}`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<HStack justify="flex-end">
			<ButtonGroup
				size="xs"
				alignItems="center"
			>
				<IconButton
					icon={<TbExternalLink />}
					text="Öffnen"
					aria-label="Öffnen"
					onClick={onOpen}
				/>
				<IconButton
					icon={
						<Text color={colors.red}>
							<IoTrash />
						</Text>
					}
					variant="ghost"
					colorScheme="red"
					aria-label="Löschen"
					onClick={onDelete}
					isLoading={isLoading}
					size="sm"
				/>
			</ButtonGroup>
		</HStack>
	);
};
