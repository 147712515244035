import type { Ticket } from "@msuite/katana";
import { Box, HStack, Text, useColorModeValue, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbPaperclip } from "react-icons/tb";

/** Props Interface */
interface TicketFilesProps {
	onOpenGallery: () => void;
	ticket: Ticket;
}

/** Functions */
export function calculateTicketFilesLength(ticket: Partial<Ticket>): number {
	const ownFilesLength = ticket.files?.length ?? 0;
	const relatedFilesLength = ticket.relatedFiles?.length ?? 0;
	const totalFilesLength = ownFilesLength + relatedFilesLength;
	return totalFilesLength;
}

export const TicketFiles: FC<TicketFilesProps> = ({ ticket }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Values */
	const fontColor = useColorModeValue("gray.700", "gray.300");

	/** Render */
	return (
		<Box>
			<HStack
				spacing={1}
				alignItems="center"
				fontSize="sm"
				_active={{
					opacity: 0.5,
				}}
				cursor="pointer"
			>
				<Box color={colors.blue}>
					<TbPaperclip />
				</Box>
				<Text color={fontColor}>{calculateTicketFilesLength(ticket)}</Text>
			</HStack>
		</Box>
	);
};
