import { moment } from "@msuite/katana";
import { Box, Grid, HStack, Text, VStack } from "@msuite/picasso";
import { useQueryState } from "nuqs";
import { type FC, useEffect, useRef } from "react";
import { ActivityItem } from "./activity-item";
import { useActivities } from "./use-activities";

/** Props Interface */
interface CalendarBoxProps {
	projectRequestId: string;
}

export const CalendarBox: FC<CalendarBoxProps> = ({ projectRequestId }) => {
	/** Hooks */
	const constraintsRef = useRef<HTMLDivElement>(null);
	const [currentDate] = useQueryState("date", {
		defaultValue: moment().format("YYYY-MM-DD"),
		clearOnDefault: false,
	});

	const { activities } = useActivities({
		projectRequestId,
		date: currentDate ?? moment().format("YYYY-MM-DD"),
	});

	/** Effects */
	useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById("calendar-box-scroll-container");
			const firstElement = element?.getElementsByClassName("activity-item")[0];
			if (!firstElement) return;
			firstElement.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}, 250);
	}, [currentDate]);

	/** Render */
	return (
		<Box
			height="0"
			flexGrow={1}
		>
			<VStack
				height="100%"
				overflowY="scroll"
				flexGrow={0}
				minH="0"
				spacing={0}
				id="calendar-box-scroll-container"
			>
				<Box
					width="100%"
					height="auto"
					position="relative"
					ref={constraintsRef}
				>
					{activities?.map((activity, index) => (
						<ActivityItem
							activity={activity}
							projectRequestId={projectRequestId}
							key={activity.id}
							index={index}
							constraintsRef={constraintsRef}
						/>
					))}
					{Array.from({ length: 24 }).map((_, i) => (
						<Grid
							templateColumns="1fr 5fr"
							height="4rem"
							flexShrink={0}
							// biome-ignore lint: no-index-key
							key={i}
						>
							<HStack
								justifyContent="flex-end"
								alignItems="flex-start"
								borderRightWidth={1}
								height="100%"
								px="0.75rem"
							>
								<Text
									fontSize="sm"
									style={{
										transform: i !== 0 ? "translateY(-50%)" : "",
									}}
								>{`${i.toString().padStart(2, "0")}:00`}</Text>
							</HStack>
							<Box
								height="100%"
								width="100%"
								borderTopWidth={i !== 0 ? 1 : 0}
							/>
						</Grid>
					))}
				</Box>
			</VStack>
		</Box>
	);
};
