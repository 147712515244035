import { db } from "@/core";
import type { ConstructionSiteDocument } from "@msuite/katana";
import { Input, StandardDataTable, VStack, useCollection, useTableOptions } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { Header } from "./header";
import { columns } from "./table-config";

/** Props Interface */
interface DocumentsProps {
	projectRequestId: string;
	headerRef: React.RefObject<HTMLDivElement>;
}

export const Documents: FC<DocumentsProps> = ({ headerRef, projectRequestId }) => {
	/** Hooks */
	const tableOptions = useTableOptions();
	const { data: documents, isLoading } = useCollection<ConstructionSiteDocument>(db, {
		path: `projectRequests/${projectRequestId}/documents`,
		subscribe: true,
	});

	/** Render */
	return (
		<Fragment>
			<Header
				projectRequestId={projectRequestId}
				headerRef={headerRef}
			/>
			<VStack pt="1px">
				<Input isSearch />
				<StandardDataTable
					{...tableOptions}
					data={documents}
					columns={columns}
					isLoading={isLoading}
				/>
			</VStack>
		</Fragment>
	);
};
