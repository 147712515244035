import { db } from "@/core";
import {
	moment,
	type SubcontractorPublishEntry,
	type SubcontractorTimesheetSection,
} from "@msuite/katana";
import { Box, HStack, Text, useDocument, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbCircleDashed, TbCircleFilled } from "react-icons/tb";
import { useProcessTimesheetsModalContext } from "./context";

/** Props Interface */
interface SubcontractorSectionEntryProps {
	entry: Required<Required<SubcontractorTimesheetSection>["baustellen"][number]>["entries"][number];
	constructionSiteId: string;
	subcontractorId: string;
}

export const ConstructionSiteEntry: FC<SubcontractorSectionEntryProps> = ({
	entry,
	constructionSiteId,
	subcontractorId,
}) => {
	/** Context */
	const { setSelectedPublishEntry, selectedPublishEntry } = useProcessTimesheetsModalContext();
	const { colors } = useUIContext();

	/** Hooks */
	const { data: subscribedEntry } = useDocument<SubcontractorPublishEntry>(db, {
		path: `unternehmer/${subcontractorId}/subunternehmer_timeline/${entry.id}`,
		subscribe: true,
	});

	/** Computed */
	const isSelected = entry.id === selectedPublishEntry?.entry.id;
	const isEdited = subscribedEntry?.timesheet?.entries?.some(
		(e) => e.controlled && e.controlled !== "uncontrolled",
	);

	/** Functions */
	function handleOnSelectEntry() {
		if (!isSelected) {
			setSelectedPublishEntry({
				entry,
				constructionSiteId,
				subcontractorId,
			});
		} else {
			setSelectedPublishEntry(undefined);
		}
	}

	/** Render */
	return (
		<HStack
			onClick={handleOnSelectEntry}
			ml={6}
			borderTopWidth={1}
			paddingY={3}
			_hover={{ opacity: 0.5 }}
			_active={{ opacity: 0.3 }}
			cursor="pointer"
			justify="space-between"
			pr={3}
		>
			<Text noOfLines={1}>{moment(entry.date).format("dddd, L")}</Text>
			{(isEdited || isSelected) && (
				<Box color={isSelected ? colors.emerald : colors.orange}>
					{isSelected ? <TbCircleFilled /> : <TbCircleDashed />}
				</Box>
			)}
		</HStack>
	);
};
