import { MentionInput } from "@/components/mention-input";
import { ax } from "@/core";
import type { ISiteManagersApiResponse } from "@msuite/katana";
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	Input,
	SegmentedControl,
	Select,
	VStack,
	useApi,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IoLocation } from "react-icons/io5";
import { ProjectRequestParticipants } from "./project-request-activity-participants";
import { FormError, type ProjectRequestActivityFormSchema } from "./schema";

/** Props Interface */
interface ProjectRequestActivityFormProps {
	projectRequestId: string;
}

export const ProjectRequestActivityForm: FC<ProjectRequestActivityFormProps> = ({
	projectRequestId,
}) => {
	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});

	/** Context */
	const methods = useFormContext<ProjectRequestActivityFormSchema>();
	const [currentType, currentNote] = useWatch({
		control: methods.control,
		name: ["activityType", "note"],
	});

	/** Render */
	return (
		<VStack>
			<FormControl>
				<SegmentedControl
					value={currentType}
					onChange={(value) => methods.setValue("activityType", value)}
					options={[
						{ label: "Anruf", value: "call" },
						{ label: "Meeting", value: "meeting" },
						{ label: "E-Mail", value: "email" },
						{ label: "Aufgabe", value: "task" },
						{ label: "Frist", value: "deadline" },
					]}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Titel</FormLabel>
				<Input
					{...methods.register("title")}
					placeholder="z.B. Besprechung mit Maximilian Mustermann"
				/>
				<FormError name="title" />
			</FormControl>
			<Grid
				templateColumns="2fr 1fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>Datum</FormLabel>
					<Input
						type="date"
						{...methods.register("date")}
					/>
				</FormControl>
				<FormControl>
					<FormLabel>Start</FormLabel>
					<Input
						type="time"
						{...methods.register("startTime")}
					/>
				</FormControl>
				<FormControl>
					<FormLabel>Ende</FormLabel>
					<Input
						type="time"
						{...methods.register("endTime")}
					/>
				</FormControl>
			</Grid>
			<Box mt={-6}>
				<FormError name="startTime" />
			</Box>
			<FormControl>
				<FormLabel>Priorität</FormLabel>
				<Select
					{...methods.register("priority")}
					placeholder="Bitte auswählen"
				>
					<option value="low">Niedrig</option>
					<option value="medium">Mittel</option>
					<option value="high">Hoch</option>
				</Select>
			</FormControl>
			{currentType === "meeting" && (
				<FormControl>
					<FormLabel>Ort</FormLabel>
					<Input
						leftIcon={<IoLocation />}
						{...methods.register("location")}
						placeholder="z.B. Schillerstraße 3, 13158 Berlin"
					/>
				</FormControl>
			)}
			<FormControl>
				<FormLabel>Notiz</FormLabel>
				<MentionInput
					value={currentNote ?? ""}
					onChange={(value) => methods.setValue("note", value)}
					minHeight="10rem"
					items={
						data?.employees?.map((employee) => ({
							id: employee.id,
							display: `${employee.vorname} ${employee.nachname}`,
						})) ?? []
					}
				/>
			</FormControl>
			<ProjectRequestParticipants projectRequestId={projectRequestId} />
		</VStack>
	);
};
