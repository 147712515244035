import { DropzoneModalContext } from "@/context/dropzone-modal";
import type { MimeTypes } from "@msuite/katana";
import { Box, useUIContext } from "@msuite/picasso";
import { type FC, memo, useContext, useMemo } from "react";
import {
	BsFileEarmarkPdfFill,
	BsFillFileEarmarkFill,
	BsFillFileEarmarkFontFill,
	BsFillFileEarmarkImageFill,
	BsFillFileEarmarkMusicFill,
	BsFillFileEarmarkPlayFill,
} from "react-icons/bs";
import { TbChecks } from "react-icons/tb";

/** Props Interface */
interface MimeTypeIconProps {
	type: string;
	index: number;
}

export const MimeTypeIcon: FC<MimeTypeIconProps> = ({ type, index }) => {
	/** Context */
	const { colors } = useUIContext();
	const { brand, green } = colors;

	const { progress } = useContext(DropzoneModalContext);

	/** Memoized */
	const mimeType: MimeTypes = useMemo(() => {
		if (type.includes("octet-stream")) return "image";
		return type.split("/")[0] as "image" | "video" | "audio" | "text" | "application";
	}, [type]);

	const Icon = memo(() => {
		switch (mimeType) {
			case "image":
				return <BsFillFileEarmarkImageFill />;
			case "video":
				return <BsFillFileEarmarkPlayFill />;
			case "audio":
				return <BsFillFileEarmarkMusicFill />;
			case "text":
				return <BsFillFileEarmarkFontFill />;
			case "application":
				return <BsFileEarmarkPdfFill />;
			default:
				return <BsFillFileEarmarkFill />;
		}
	});

	Icon.displayName = "Icon";

	const isUploaded = useMemo(() => {
		return progress.file_nr > index;
	}, [index, progress.file_nr]);

	/** Render */
	return (
		<Box
			fontSize="lg"
			color={isUploaded ? green : brand}
		>
			{isUploaded ? <TbChecks /> : <Icon />}
		</Box>
	);
};
