import { ClientAutoCompleteItem } from "@/components/construction-site/client-auto-complete-item";
import { db } from "@/core";
import type { ConstructionSiteContact } from "@msuite/katana";
import {
	AutoComplete,
	type AutoCompleteOption,
	FormControl,
	FormHelperText,
	FormLabel,
	Input,
	useCollection,
} from "@msuite/picasso";
import { type FC, Fragment, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { TbArrowRight } from "react-icons/tb";
import { FormError, type ProjectRequestFormType } from "./schema";

export const ProjectRequestFormClient: FC = () => {
	/** State */
	const [showClientNameField, setShowClientNameField] = useState<boolean>(false);

	/** Hooks */
	const methods = useFormContext<ProjectRequestFormType>();
	const [clientId] = useWatch({ control: methods.control, name: ["clientId"] });
	const { data: contacts, isFetching } = useCollection<ConstructionSiteContact>(db, {
		path: "_contactBook",
		orderBy: "name",
		subscribe: true,
	});

	/** Functions */
	function handleSelectClientId(
		changeItem: AutoCompleteOption<ConstructionSiteContact> | undefined,
	) {
		if (!changeItem) return;
		if (!changeItem?.value) return;
		methods.setValue("clientId", changeItem?.value);
		methods.setValue("clientName", undefined);
		methods.resetField("clientName");
		setShowClientNameField(false);
	}

	function handleOnClickEmptyElement(searchValue: string) {
		setShowClientNameField(true);
		setTimeout(() => {
			methods.setValue("clientName", searchValue);
			methods.setValue("clientId", undefined);
		}, 0);
		const legacyClientElement = document.getElementById("client-name-field");
		if (legacyClientElement) {
			setTimeout(() => {
				legacyClientElement.scrollIntoView({ behavior: "smooth" });
				legacyClientElement.focus();
			}, 0);
		}
	}

	if (isFetching) return null;

	/** Render */
	return (
		<Fragment>
			<FormControl>
				<FormLabel>Auftraggeber</FormLabel>
				<AutoComplete
					alwaysShowEmptyElement
					key={clientId}
					placeholder="Auftraggeber suchen"
					defaultValue={
						clientId
							? {
									value: clientId,
									label: contacts.find((contact) => contact.id === clientId)?.name ?? "N.A.",
								}
							: undefined
					}
					options={contacts.map((contact) => ({
						value: contact.id,
						item: contact,
						label: contact.name,
					}))}
					renderItem={ClientAutoCompleteItem}
					onChange={handleSelectClientId}
					emptyElement={{
						icon: <TbArrowRight />,
						label: "Mit vorläufigem Auftraggeber fortfahren.",
						onClick: handleOnClickEmptyElement,
					}}
				/>
				<FormError name="clientId" />
			</FormControl>

			{showClientNameField && (
				<FormControl>
					<FormLabel>Vorläufiger Auftraggeber</FormLabel>
					<Input
						{...methods.register("clientName")}
						id="client-name-field"
						placeholder="z.B. Muster GmbH"
					/>
					<FormHelperText>
						Diese Angabe wird nur vorübergehend verwendet und ist durch einen Kontakt aus der
						Datenbank zu ersetzen.
					</FormHelperText>
				</FormControl>
			)}
		</Fragment>
	);
};
