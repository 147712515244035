import { db } from "@/core";
import { OfferDetailsModal } from "@/modals/my-space/offer-details-modal";
import type { ICallNoteReasonOffer, Offer } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { Label, Text, VStack, useDisclosure, useDocument } from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { useCallNoteOverviewContext } from "./context";

export const OverviewReasonOffer: FC = () => {
	/** Context */
	const { callNote } = useCallNoteOverviewContext();

	/** Hooks */
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { data } = useDocument<Offer>(db, {
		path: `offers/${(callNote.reason as ICallNoteReasonOffer).offerId}`,
	});

	/** Fallback */
	if (callNote.reason.type !== "offer") return null;

	/** Render */
	return (
		<Fragment>
			<VStack
				spacing={4}
				paddingY={3}
				paddingX={3.5}
				rounded="xl"
				borderWidth={1}
				cursor="pointer"
				onClick={onOpen}
			>
				<VStack spacing={0}>
					<Label>Angebotsdatum</Label>
					<Text>{moment(data?.offerDate).format("dddd, DD.MM.YYYY")}</Text>
				</VStack>
				<VStack spacing={0}>
					<Label>Angebotsnummer</Label>
					<Text>{data?.offerNumber}</Text>
				</VStack>
				<VStack spacing={0}>
					<Label>Projekt</Label>
					<Text>{data?.projectName}</Text>
				</VStack>
			</VStack>
			<OfferDetailsModal
				isOpen={isOpen}
				onClose={onClose}
				offerId={callNote.reason.offerId}
				withEdit={false}
			/>
		</Fragment>
	);
};
