import { ax, db } from "@/core";
import { ProjectRequestActivityForm } from "@/forms/project-request-activity-form";
import {
	type ProjectRequestActivityFormSchema,
	projectRequestActivityFormSchema,
} from "@/forms/project-request-activity-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import type { ProjectRequestTimelineActivityEntry } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface EditProjectRequestActivityModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
	activityId: string;
}

export const EditProjectRequestActivityModal: FC<EditProjectRequestActivityModalProps> = ({
	isOpen,
	onClose,
	projectRequestId,
	activityId,
}) => {
	/** Hooks */
	const methods = useForm<ProjectRequestActivityFormSchema>({
		defaultValues: async () => {
			const docRef = doc(db, `projectRequests/${projectRequestId}/requestTimeline/${activityId}`);
			const activity = (
				await getDoc(docRef)
			).data() as Required<ProjectRequestTimelineActivityEntry>;
			return activity;
		},
		resolver: zodResolver(projectRequestActivityFormSchema),
	});

	/** Functions */
	async function handleOnSubmit(values: ProjectRequestActivityFormSchema) {
		try {
			await ax.patch(`/v2/project-requests/${projectRequestId}/activity/${activityId}`, {
				data: { values },
			});
			handleOnClose();
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnReject(errors: typeof methods.formState.errors) {
		console.error(errors);
		toast.error("Fehlgeschlagen");
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Aktivität bearbeiten</ModalHeader>
						<ModalBody>
							<ProjectRequestActivityForm
								key={JSON.stringify(methods.formState.defaultValues)}
								projectRequestId={projectRequestId}
							/>
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={methods.formState.isSubmitting}
								>
									Aktualisieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
