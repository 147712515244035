import { SurveyContext } from "@/context/survey";
import { QuestionContext } from "@/context/survey-question";
import { ax } from "@/core";
import type { ISurveyResponseEntry } from "@msuite/katana";
import {
	FormControl,
	FormHelperText,
	FormLabel,
	Spacer,
	Textarea,
	VStack,
	useAuthContext,
} from "@msuite/picasso";
import { type FC, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Description } from "../description";
import { Prompt } from "../prompt";
import { QuestionStepper } from "../question-stepper";
import { RequiredIndicator } from "../required-indicator";

export const FreetextQuestion: FC = () => {
	/** Context */
	const { user } = useAuthContext();
	const { getCurrentResponse, activeElement, response, workspaceId, surveyPath, survey } =
		useContext(SurveyContext);
	const { question } = useContext(QuestionContext);

	/** @msuite/picasso */
	const { register, getValues, control } = useForm<ISurveyResponseEntry>({
		defaultValues: getCurrentResponse(question) || {
			questionId: question?.id,
			sectionId: activeElement?.sectionId,
			answer: [""] as any,
			answeredBy: user?.id,
			repeatKey: activeElement?.repeatKey,
		},
	});

	const answer = useWatch({ control, name: "answer.0" });
	const isDisabled = answer?.length === 0 && question?.required;

	const performUpdate = async () => {
		try {
			await ax.patch(`/survey/${survey?.id}/workspace/${workspaceId}/response/${response?.id}`, {
				data: { values: getValues(), surveyPath },
			});
		} catch (err) {
			console.error(err);
		}
	};

	/** Render */
	return (
		<>
			<VStack
				align="stretch"
				spacing={0}
			>
				<Prompt />
				<RequiredIndicator />
			</VStack>
			<Description />
			<FormControl>
				<FormLabel>Antwort</FormLabel>
				<Textarea
					minHeight="200px"
					placeholder="Beliebige Antwort eingeben..."
					bg="white"
					maxLength={question?.maxLength || 1000}
					{...register("answer.0")}
				/>
				<FormHelperText>
					{(question?.maxLength || 0) > 0 && `${answer.length} / ${question?.maxLength}`}
				</FormHelperText>
			</FormControl>
			<Spacer />
			<QuestionStepper
				onUpdate={performUpdate}
				forwardDisabled={isDisabled}
				isSkippable={question?.required ? false : (answer?.length || 0) === 0}
			/>
		</>
	);
};
