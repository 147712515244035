import { HStack } from "@msuite/picasso";
import type { FC } from "react";
import { useSalesStore } from "../sales/store";
import { ProjectRequestStateDiagramItem } from "./project-request-state-diagram-item";

/** Props Interface */
interface ProjectRequestStateDiagramProps {
	projectRequestId: string;
}

export const ProjectRequestStateDiagram: FC<ProjectRequestStateDiagramProps> = ({
	projectRequestId,
}) => {
	/** Store */
	const salesStore = useSalesStore();

	/** Render */
	return (
		<HStack spacing={0}>
			{salesStore.salesConfiguration?.columns.map((column, index) => (
				<ProjectRequestStateDiagramItem
					isFirst={index === 0}
					isLast={index === (salesStore.salesConfiguration?.columns?.length ?? 1) - 1}
					column={column}
					key={column.id}
					referenceArray={salesStore.salesConfiguration?.columns.map((column) => column.id) ?? []}
					projectRequestId={projectRequestId}
				/>
			))}
		</HStack>
	);
};
