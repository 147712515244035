import { ax } from "@/core";
import { AddProjectRequestLoseReasonModal } from "@/modals/sales/add-project-request-lose-reason-modal";
import type { ProjectRequest } from "@msuite/katana";
import { Button, HStack, ModalButtonGroup, useDisclosure } from "@msuite/picasso";
import { type FC, Fragment, useState } from "react";

/** Props Interface */
interface ProjectRequestFinishingProps {
	projectRequestId: string;
	projectRequest: ProjectRequest | undefined;
}

export const ProjectRequestFinishing: FC<ProjectRequestFinishingProps> = ({
	projectRequestId,
	projectRequest,
}) => {
	/** Hooks */
	const [resolveIsLoading, setResolveIsLoading] = useState<boolean>(false);
	const [actionInProgress, setActionInProgress] = useState<"win" | "lose" | null>(null);
	const addProjectRequestLoseReasonModal = useDisclosure();

	/** Functions */
	async function handleOnMarkAsWon() {
		try {
			setActionInProgress("win");
			await ax.patch(`/v2/project-requests/${projectRequestId}/final-result?result=win`);
		} catch (error) {
			console.error(error);
		} finally {
			setActionInProgress(null);
		}
	}

	async function handleOnMarkAsLost() {
		try {
			setActionInProgress("lose");
			addProjectRequestLoseReasonModal.onOpen();
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnMarkAsLostComplete() {
		setActionInProgress(null);
	}

	async function handleOnResolve() {
		try {
			setResolveIsLoading(true);
			await ax.patch(`/v2/project-requests/${projectRequestId}/reopen`);
		} catch (error) {
			console.error(error);
		} finally {
			setResolveIsLoading(false);
		}
	}

	if (projectRequest?.finalResult) {
		return (
			<HStack spacing={2}>
				<Button
					variant="solid"
					colorScheme={projectRequest.finalResult === "win" ? "emerald" : "red"}
					isDisabled
					opacity="1 !important"
					cursor="default !important"
				>
					{projectRequest.finalResult === "win" ? "Gewonnen" : "Verloren"}
				</Button>
				<Button
					colorScheme="gray"
					size="sm"
					onClick={handleOnResolve}
					isLoading={resolveIsLoading}
				>
					Wieder öffnen
				</Button>
			</HStack>
		);
	}

	/** Render */
	return (
		<Fragment>
			<HStack
				justify="flex-end"
				alignItems="flex-start"
			>
				<ModalButtonGroup>
					<Button
						colorScheme="emerald"
						onClick={handleOnMarkAsWon}
						isDisabled={actionInProgress !== null}
						isLoading={actionInProgress === "win"}
					>
						Gewonnen
					</Button>
					<Button
						colorScheme="red"
						onClick={handleOnMarkAsLost}
						isDisabled={actionInProgress !== null}
						isLoading={actionInProgress === "lose"}
					>
						Verloren
					</Button>
				</ModalButtonGroup>
			</HStack>
			<AddProjectRequestLoseReasonModal
				isOpen={addProjectRequestLoseReasonModal.isOpen}
				onClose={addProjectRequestLoseReasonModal.onClose}
				projectRequestId={projectRequestId}
				onComplete={handleOnMarkAsLostComplete}
			/>
		</Fragment>
	);
};
