import { DropzoneModalContext } from "@/context/dropzone-modal";
import { Box, Center, HStack, Link, Text, useUIContext, VStack } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { TbUpload } from "react-icons/tb";

export const Dropzone: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { getRootProps, getInputProps, isDragActive, maxFiles } = useContext(DropzoneModalContext);
	const { whiteAdjusted, brand, gray } = colors;

	/** Render */
	return (
		<Center
			{...getRootProps?.()}
			bg={whiteAdjusted}
			py="3.5rem"
			rounded="md"
			borderWidth={1}
			borderStyle={isDragActive ? "solid" : "dashed"}
			borderColor={isDragActive ? brand : undefined}
			_hover={{
				borderColor: brand,
				borderStyle: "solid",
				"#icon-tb-upload": {
					color: brand,
				},
			}}
			cursor="pointer"
		>
			<input {...getInputProps?.()} />
			<VStack
				spacing={3}
				alignItems="center"
			>
				<Box
					fontSize="2xl"
					color={isDragActive ? brand : gray}
					id="icon-tb-upload"
				>
					<TbUpload />
				</Box>
				<HStack
					spacing={1}
					fontSize="sm"
				>
					<Link>durchsuchen</Link>
					<Text
						color="gray.500"
						textAlign="center"
					>
						oder Dateien hier ablegen (max. {maxFiles} {maxFiles === 1 ? "Datei" : "Dateien"})
					</Text>
				</HStack>
			</VStack>
		</Center>
	);
};
