import { usePlanContext } from "@/context/plan";
import { db } from "@/core";
import { getDailyReferenceString } from "@/sites/plan/dailies/daily/use-daily";
import type { ConstructionSite } from "@msuite/katana";
import type { ContextMenuOptions } from "@msuite/picasso";
import { toast, useAuthContext, type useDisclosure, useUIContext } from "@msuite/picasso";
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc } from "firebase/firestore";
import { useRef } from "react";
import { IoCalendarClear, IoInformation, IoPaperPlane, IoPencil, IoScan } from "react-icons/io5";
import { TbCircleDashed, TbCircleFilled, TbCube } from "react-icons/tb";
import { openGoogleMaps } from "util/construction-site";
import type { ContextMenuType } from "./construction-site-card-content";

export const useContextMenu = (
	contextMenuType: ContextMenuType,
	constructionSite: ConstructionSite | undefined,
	constructionSiteId: string | undefined,
	editConstructionSiteModal: ReturnType<typeof useDisclosure>,
	showAllActiveTickets: boolean,
	setShowAllActiveTickets: (value: boolean) => void,
) => {
	/** Hooks */
	const contextMenuRef = useRef();

	/** Context */
	const { weeklyReference, activeDate } = usePlanContext();
	const { validation } = useAuthContext();
	const { colors } = useUIContext();

	/** Functions */
	async function openInformation() {
		try {
			window.open(`/construction-sites/${constructionSiteId}`, "_blank");
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnOpenGoogleMaps() {
		openGoogleMaps(constructionSite);
	}

	async function removeFromWeekly() {
		try {
			if (!constructionSiteId) throw new Error("No construction site id");
			const weeklyRef = doc(db, weeklyReference);
			await updateDoc(weeklyRef, {
				baustellen: arrayRemove(constructionSiteId),
			});
		} catch (error) {
			toast.error("Fehler beim Entfernen der Baustelle aus der Wochenplanung.");
			console.error(error);
		}
	}

	async function handleMarkAllTickets() {
		try {
			const dailyReference = getDailyReferenceString(weeklyReference, activeDate);
			const dailyRef = doc(db, dailyReference);
			const tickets = (constructionSite?.tickets ?? []).map((ticket) => ticket.id);
			await updateDoc(dailyRef, {
				na: arrayUnion(...tickets),
			}).catch(() => {
				setDoc(dailyRef, {
					exists: true,
					na: tickets,
				});
			});
		} catch (error) {
			toast.error("Fehler beim Markieren aller Tickets.");
		}
	}

	function toggleShowAllActiveTickets() {
		setShowAllActiveTickets(!showAllActiveTickets);
	}

	/** Builders */
	function createDailyContextMenuOptions(): (ContextMenuOptions[0] | undefined)[] {
		return [
			{
				label: "Informationen",
				onClick: openInformation,
				icon: <IoInformation />,
			},
			"divider",
			...create3DModelMenuOption(),
			{
				label: "Bearbeiten",
				onClick: editConstructionSiteModal.onOpen,
				icon: <IoPencil />,
			},
			"divider",
			{
				label: "Auf Google Maps anzeigen",
				onClick: handleOnOpenGoogleMaps,
				icon: <IoPaperPlane />,
			},
		];
	}

	function createWeeklyContextMenuOptions(): (ContextMenuOptions[0] | undefined)[] {
		return [
			{
				label: "Informationen",
				onClick: openInformation,
				icon: <IoInformation />,
			},
			"divider",
			...create3DModelMenuOption(),
			{
				label: "Bearbeiten",
				onClick: editConstructionSiteModal.onOpen,
				icon: <IoPencil />,
			},
			validation?.isAdmin
				? {
						label: "Aus Kalenderwoche entfernen",
						onClick: removeFromWeekly,
						icon: <IoCalendarClear />,
					}
				: undefined,
			"divider",
			{
				label: "Alle aktiven Tickets anzeigen",
				onClick: toggleShowAllActiveTickets,
				icon: showAllActiveTickets ? <TbCircleFilled /> : <TbCircleDashed />,
				iconColor: showAllActiveTickets ? colors.green : colors.gray,
			},
			validation?.isAdmin
				? {
						label: "Alle Tickets markieren",
						onClick: handleMarkAllTickets,
						icon: <TbCircleFilled />,
						iconColor: colors.yellow,
					}
				: undefined,
			validation?.isAdmin
				? {
						label: "Alle Tickets auswählen",
						onClick: () => (contextMenuRef.current as any)?.selectAllTickets?.(),
						icon: <IoScan />,
					}
				: undefined,
			"divider",
			{
				label: "Auf Google Maps anzeigen",
				onClick: handleOnOpenGoogleMaps,
				icon: <IoPaperPlane />,
			},
		];
	}

	function create3DModelMenuOption(): ContextMenuOptions | Array<undefined> {
		if (!constructionSite) return [undefined];
		if (!constructionSite.trimbleConnect?.length) return [undefined];
		if (constructionSite.trimbleConnect.length === 1) {
			const options: ContextMenuOptions = [
				{
					label: "3D-Modell anzeigen",
					onClick: () => {
						window.open(constructionSite.trimbleConnect?.at(0)?.trimbleLink, "_blank");
					},
				},
				"divider",
			];
			return options;
		}
		const options: ContextMenuOptions = [
			{
				label: "3D-Modelle anzeigen",
				icon: <TbCube />,
				subOptions: constructionSite.trimbleConnect.map((trimbleConnect, index) => ({
					icon: index + 1,
					label: `${trimbleConnect.name || "3D-Modell"}`,
					onClick: () => {
						window.open(trimbleConnect.trimbleLink, "_blank");
					},
				})),
			},
			"divider",
		];
		return options;
	}

	function createContextMenuOptions(
		contextMenuType: ContextMenuType,
	): (ContextMenuOptions[0] | undefined)[] {
		if (contextMenuType === "daily") return createDailyContextMenuOptions();
		if (contextMenuType === "weekly") return createWeeklyContextMenuOptions();
		return [];
	}

	const contextMenuOptions = createContextMenuOptions(contextMenuType).filter(
		Boolean,
	) as ContextMenuOptions;

	return {
		contextMenuOptions,
		contextMenuRef,
	};
};
