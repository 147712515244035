import type { ZodSatisfy } from "@/util/zod";
import {
	type ActivityParticipant,
	type ProjectRequestTimelineActivityEntry,
	moment,
} from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import { z } from "zod";

export const projectRequestActivityFormSchema = z
	.object({
		type: z.string().default(() => "activity"),
		activityType: z.string(),
		date: z.string(),
		title: z.string().min(1, "Bitte geben Sie einen Titel an"),

		note: z.string().optional(),
		mentions: z.array(z.string()).optional(),
		startTime: z.string().optional(),
		endTime: z.string().optional(),
		location: z.string().optional(),
		priority: z.string().default(() => "medium"),
		state: z.string().default(() => "open"),
		participants: z
			.array(
				z.object({
					id: z.string(),
					displayName: z.string(),
					email: z.string().optional(),
				} satisfies ZodSatisfy<ActivityParticipant>),
			)
			.optional(),

		updatedAt: z.string().optional(),
		updatedBy: z.string().optional(),
		createdAt: z.string().optional(),
		createdBy: z.string().optional(),
	} satisfies ZodSatisfy<ProjectRequestTimelineActivityEntry>)
	.superRefine((value, context) => {
		if (moment(value.startTime, "HH:mm").isAfter(moment(value.endTime, "HH:mm"))) {
			context.addIssue({
				path: ["startTime"],
				code: z.ZodIssueCode.custom,
				message: "Startzeit muss vor der Endzeit liegen",
			});
		}

		return true;
	});

export type ProjectRequestActivityFormSchema = z.infer<typeof projectRequestActivityFormSchema>;

export const FormError = FormErrorMessage<ProjectRequestActivityFormSchema>;
