import { FormControl, FormHelperText, Input, Spinner, useDebouncedValue } from "@msuite/picasso";
import { type FC, useEffect, useState } from "react";

/** Props Interface */
interface ExistingSearchBoxInputProps {
	onChange: (value: string) => void;
}

export const ExistingSearchBoxInput: FC<ExistingSearchBoxInputProps> = ({ onChange }) => {
	/** State */
	const [value, setValue] = useState<string>("");

	/** State */
	const debounced = useDebouncedValue(value, 500);
	const isLoading = debounced !== value;

	/** Effects */
	useEffect(() => {
		if (debounced.length < 3 && debounced.length !== 0) return;
		onChange(debounced);
	}, [debounced, onChange]);

	useEffect(() => {
		if (value.length === 0) onChange(value);
	}, [value, onChange]);

	/** Render */
	return (
		<FormControl>
			<Input
				placeholder="z.B. 223 1234"
				value={value}
				rightIcon={
					<Spinner
						opacity={isLoading ? 1 : 0}
						size="sm"
						thickness="2px"
					/>
				}
				onChange={(e) => setValue(e.target.value)}
			/>
			<FormHelperText>min. 3 Zeichen</FormHelperText>
		</FormControl>
	);
};
