import { db } from "@/core";
import type { RentListAnswer, RentListItem } from "@msuite/katana";
import {
	ButtonGroup,
	HStack,
	Heading,
	Text,
	UnstyledIconButton,
	VStack,
	useAuthContext,
	useUIContext,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { type FC, useState } from "react";
import { BsCheck, BsQuestion, BsX } from "react-icons/bs";
import { ReasonPopover } from "./reason-popover";
import { ResponseComment } from "./response-comment";

/** Props Interface */
interface ResponseCardProps {
	item: RentListItem;
}

export const ResponseCard: FC<ResponseCardProps> = ({ item }) => {
	/** State */
	const [isLoading, setIsLoading] = useState<RentListAnswer | undefined>(undefined);

	/** Context */
	const { userId } = useAuthContext();
	const { colors } = useUIContext();
	const { constructionSiteId, constructionSite } = item;

	/** Functions */
	async function performUpdate(answer: RentListAnswer) {
		try {
			setIsLoading(answer);
			const rentListItemRef = doc(db, `user/${userId}/rentlist/${constructionSiteId}`);
			if (item.answer === answer) {
				await updateDoc(rentListItemRef, { answer: "unanswered" });
			} else {
				await updateDoc(rentListItemRef, { answer });
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(undefined);
		}
	}

	/** Render */
	return (
		<VStack
			bg={colors.whiteAdjusted}
			alignItems="stretch"
			p="1rem"
			rounded="xl"
			borderWidth={1}
			spacing={0}
			id={constructionSiteId}
		>
			<HStack justifyContent="space-between">
				<VStack
					alignItems="stretch"
					spacing={3}
					pr="5rem"
				>
					<HStack spacing={2}>
						<Heading
							size="sm"
							noOfLines={1}
						>
							{constructionSite.bauvorhaben || "N.A."}
						</Heading>
						<ReasonPopover reason={item.reason} />
					</HStack>
					<Text
						fontSize="sm"
						color="gray.500"
						noOfLines={1}
						lineHeight="1"
					>
						{constructionSite.nummer} / {constructionSite.auftraggeber}
					</Text>
					<Text
						fontSize="sm"
						color="gray.500"
						noOfLines={1}
						lineHeight="1"
					>
						{constructionSite.strasse || "--"} {constructionSite.hausnummer || "--"},{" "}
						{constructionSite.plz || "--"} {constructionSite.ort || "--"}
					</Text>
				</VStack>

				<VStack
					alignItems="flex-end"
					justifyContent="center"
				>
					<ButtonGroup size="sm">
						<UnstyledIconButton
							colorScheme={item.answer === "yes" ? "green" : "gray"}
							icon={<BsCheck />}
							aria-label="check"
							isLoading={isLoading === "yes"}
							isDisabled={isLoading !== undefined}
							onClick={() => performUpdate("yes")}
						/>
						<UnstyledIconButton
							icon={<BsX />}
							colorScheme={item.answer === "no" ? "red" : "gray"}
							aria-label="check"
							isLoading={isLoading === "no"}
							isDisabled={isLoading !== undefined}
							onClick={() => performUpdate("no")}
						/>
						<UnstyledIconButton
							icon={<BsQuestion />}
							colorScheme={item.answer === "maybe" ? "blue" : "gray"}
							aria-label="check"
							isLoading={isLoading === "maybe"}
							isDisabled={isLoading !== undefined}
							onClick={() => performUpdate("maybe")}
						/>
					</ButtonGroup>
				</VStack>
			</HStack>
			<ResponseComment item={item} />
		</VStack>
	);
};
