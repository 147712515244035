import { VStack, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { CalendarBox } from "./calendar-box";
import { Controls } from "./controls";

/** Props Interface */
interface CalendarProps {
	projectRequestId: string;
}

export const Calendar: FC<CalendarProps> = ({ projectRequestId }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<VStack
			backgroundColor={colors.whiteAdjusted}
			mb={6}
			rounded="lg"
			spacing={0}
			borderWidth={1}
			overflowY="hidden"
			flexGrow={0}
			minH="0"
		>
			<Controls />
			<CalendarBox projectRequestId={projectRequestId} />
		</VStack>
	);
};
