import { db } from "@/core";
import { DocumentCommentForm } from "@/forms/document-comment-form";
import { type DocumentComment, generateId, moment } from "@msuite/katana";
import {
	BlankSlate,
	Button,
	Divider,
	FormLabel,
	HStack,
	Label,
	Text,
	VStack,
	useAuthContext,
	useCollection,
	useUIContext,
} from "@msuite/picasso";
import { doc, setDoc } from "firebase/firestore";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoFileTray } from "react-icons/io5";
import { useCallNoteOverviewContext } from "./context";

export const OverviewCommentBox: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { callNote } = useCallNoteOverviewContext();
	const { user } = useAuthContext();

	/** Hooks */
	const methods = useForm<DocumentComment>();
	const { data: callNotesComments } = useCollection<DocumentComment>(db, {
		path: `callNotes/${callNote.id}/comments`,
		subscribe: true,
		orderBy: "createdAt",
		orderByDirection: "asc",
	});

	/** Functions */
	async function handleOnSubmit(data: DocumentComment) {
		try {
			if (!user) return;
			const id = generateId();
			data.id = id;
			data.createdAt = moment().toISOString();
			data.createdBy = user;
			methods.reset();
			const commentRef = doc(db, `callNotes/${callNote.id}/comments/${id}`);
			await setDoc(commentRef, data);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Divider />
			<VStack spacing={2}>
				<FormLabel>Kommentare</FormLabel>
				{!callNotesComments?.length && (
					<BlankSlate
						heading="Keine Kommentare"
						icon={<IoFileTray />}
						minH="100px"
					/>
				)}
				{callNotesComments?.map((comment) => (
					<VStack
						key={comment.id}
						spacing={1}
						padding={3}
						borderWidth={1}
						backgroundColor={colors.grayLight}
						rounded="lg"
					>
						<HStack justify="space-between">
							<Label>
								{comment.createdBy?.vorname} {comment.createdBy?.nachname}
							</Label>
							<Label fontWeight="normal">
								{moment(comment.createdAt).format("DD.MM.YYYY HH:mm")}
							</Label>
						</HStack>
						<Text>{comment.comment}</Text>
					</VStack>
				))}
			</VStack>
			<form onSubmit={methods.handleSubmit(handleOnSubmit)}>
				<VStack spacing={2}>
					<DocumentCommentForm />
					<HStack justify="flex-end">
						<Button
							isLoading={methods.formState.isSubmitting}
							type="submit"
						>
							Senden
						</Button>
					</HStack>
				</VStack>
			</form>
		</FormProvider>
	);
};
