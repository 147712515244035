import { ax } from "@/core";
import type { StatementCleanedSite } from "@msuite/katana";
import { moment, searchify, wait } from "@msuite/katana";
import {
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
	SegmentedControl,
	VStack,
	toast,
	useApi,
	useAuthContext,
} from "@msuite/picasso";
import { YearlyStatementModalContext } from "context/yearly-statement-modal";
import { type FC, useState } from "react";
import { Footer } from "./footer";
import { ResponseCard } from "./response-card";

/** Props Interface */
interface YearlyStatementModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export type TYearlyStatementModalView = "all" | "unanswered";

export const YearlyStatementModal: FC<YearlyStatementModalProps> = ({ isOpen, onClose }) => {
	/** Context */
	const { userId } = useAuthContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentView, setCurrentView] = useState<TYearlyStatementModalView>("all");
	const [searchValue, setSearchValue] = useState<string>("");

	/** Hooks */
	const { data } = useApi<{ cleanedManagerSites: StatementCleanedSite[] }>(ax, {
		url: `/user/${userId}/yearly-statement/${moment().year()}`,
		dependencies: ["yearly-statement-sites"],
	});

	/** Computed */
	const filteredSites: StatementCleanedSite[] =
		searchValue.length > 0
			? searchify(data?.cleanedManagerSites ?? [], searchValue)
			: (data?.cleanedManagerSites ?? []);

	/** Functions */
	async function mockApiCall() {
		setIsLoading(true);
		await wait(250);
		toast.success("Änderungen gespeichert");
		onClose();
		setIsLoading(false);
	}

	/** Render */
	return (
		<YearlyStatementModalContext.Provider
			value={{ currentView, setCurrentView, sites: data?.cleanedManagerSites }}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size="4xl"
			>
				<ModalOverlay />
				<ModalContent maxW="70rem">
					<ModalCloseButton />
					<ModalHeader>
						<VStack>
							<ModalTitle>Jahresendabrechnung {moment().year()}</ModalTitle>
							<SegmentedControl
								value={currentView}
								onChange={setCurrentView}
								options={[
									{ label: "Alle", value: "all" },
									{ label: "Unbeantwortet", value: "unanswered" },
								]}
							/>
							<Input
								isSearch
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
						</VStack>
					</ModalHeader>
					<ModalBody>
						<VStack align="stretch">
							{filteredSites.map((doc) => (
								<ResponseCard
									{...doc}
									key={doc.site.id}
								/>
							))}
						</VStack>
					</ModalBody>
					<Footer
						onPerform={mockApiCall}
						isLoading={isLoading}
					/>
				</ModalContent>
			</Modal>
		</YearlyStatementModalContext.Provider>
	);
};
