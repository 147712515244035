import { BlankSlate, Box, HStack, Portal, SegmentedControl } from "@msuite/picasso";
import { useQueryState } from "nuqs";
import { type FC, Fragment } from "react";
import { TbFilePlus } from "react-icons/tb";
import { TimelineActions } from "../timeline-actions";
import { TimelineItem } from "./timeline-item";
import { useTimelineSubscriber } from "./use-timeline-subscriber";

/** Props Interface */
interface TimelineProps {
	projectRequestId: string;
	headerRef: React.RefObject<HTMLDivElement>;
}

export const Timeline: FC<TimelineProps> = ({ projectRequestId, headerRef }) => {
	/** State */
	const [currentType, setCurrentType] = useQueryState("filter", {
		defaultValue: "all",
		clearOnDefault: false,
	});

	/** Hooks */
	const { entries } = useTimelineSubscriber({
		projectRequestId,
		type: currentType,
	});

	/** Render */
	return (
		<Fragment>
			<Portal containerRef={headerRef}>
				<TimelineActions projectRequestId={projectRequestId} />
			</Portal>
			<HStack
				mb={4}
				position="sticky"
				top={0}
				zIndex={9}
				justifyContent="space-between"
			>
				<SegmentedControl
					withBlur
					value={currentType}
					onChange={setCurrentType}
					options={[
						{ label: "Alle", value: "all" },
						{ label: "Statuswechsel", value: "state-change" },
						{ label: "Aktivitäten", value: "activity" },
						{ label: "Notizen", value: "note" },
						{ label: "Ergebnis", value: "final-result" },
					]}
				/>
			</HStack>
			{!entries.length && (
				<Box
					mb={6}
					height="100%"
				>
					<BlankSlate
						withBorder
						icon={<TbFilePlus />}
						heading="Keine Einträge vorhanden"
						subtext="Füge Daten dieser Kategorie über '+ Hinzufügen' hinzu."
					/>
				</Box>
			)}
			{entries?.map((item) => (
				<TimelineItem
					key={item.id}
					item={item}
					projectRequestId={projectRequestId}
				/>
			))}
		</Fragment>
	);
};
