import { SurveyContext } from "@/context/survey";
import { QuestionContext } from "@/context/survey-question";
import { ax } from "@/core";
import type { ISurveyResponseEntry } from "@msuite/katana";
import {
	Center,
	HStack,
	Heading,
	Spacer,
	VStack,
	useAuthContext,
	useColorModeValue,
} from "@msuite/picasso";
import { type FC, useContext } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Description } from "../description";
import { Prompt } from "../prompt";
import { QuestionStepper } from "../question-stepper";
import { RequiredIndicator } from "../required-indicator";

export const NumberQuestion: FC = () => {
	/** Context */
	const { user } = useAuthContext();
	const { survey, workspaceId, response, getCurrentResponse, activeElement, surveyPath } =
		useContext(SurveyContext);
	const { question } = useContext(QuestionContext);

	/** @msuite/picasso */
	const { getValues, setValue, control } = useForm<ISurveyResponseEntry>({
		defaultValues: getCurrentResponse(question) || {
			questionId: question?.id,
			sectionId: activeElement?.sectionId,
			answer: [""] as any,
			answeredBy: user?.id,
			repeatKey: activeElement?.repeatKey,
		},
	});

	const answer = useWatch({ control, name: "answer.0" });

	const performUpdate = async () => {
		try {
			await ax.patch(`/survey/${survey?.id}/workspace/${workspaceId}/response/${response?.id}`, {
				data: { values: getValues(), surveyPath },
			});
		} catch (err) {
			console.error(err);
		}
	};

	/** Functions */
	const handleChoice = (number: number) => {
		try {
			if (answer === (number as any)) {
				setValue("answer", []);
			} else {
				setValue("answer.0", number as any);
			}
		} catch (error) {
			console.error(error);
		}
	};

	/** Colors */
	const buttonBackgroundColor = useColorModeValue("gray.200", "gray.700");
	const buttonBackgroundColorSelected = useColorModeValue("gray.800", "gray.200");
	const buttonTextColor = useColorModeValue("gray.800", "gray.200");
	const buttonTextColorSelected = useColorModeValue("gray.200", "gray.800");

	/** Render */
	return (
		<>
			<VStack
				align="stretch"
				spacing={0}
			>
				<Prompt />
				<RequiredIndicator />
			</VStack>
			<Description />
			<HStack
				justifyContent="flex-start"
				gridAutoRows="max-content"
				gap={4}
			>
				{Array((question?.max || 0) + 1 - (question?.min || 0))
					.fill(0)
					.map((_, index) => {
						const calculatedValue = (question?.min || 0) + index + 0;
						const isSelected = (answer as any) === calculatedValue;
						return (
							<Center
								key={Math.random()}
								cursor="pointer"
								width="5rem"
								sx={{ aspectRatio: "1 / 1" }}
								color={isSelected ? buttonTextColorSelected : buttonTextColor}
								bg={isSelected ? buttonBackgroundColorSelected : buttonBackgroundColor}
								rounded="lg"
								borderWidth={1}
								onClick={() => handleChoice(calculatedValue)}
							>
								<Heading size="md">{calculatedValue}</Heading>
							</Center>
						);
					})}
			</HStack>
			<Spacer />
			<QuestionStepper
				onUpdate={async () => {
					await performUpdate();
				}}
				isSkippable={answer === undefined ? !question?.required : false}
			/>
		</>
	);
};
