import type { IDeputyMap } from "@msuite/katana";
import { moment } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useAuthContext,
	useQueryClient,
} from "@msuite/picasso";
import { ax } from "core";
import { type FC, useState } from "react";

/** Props Interface */
interface CompleteDeputiesModalProps {
	isOpen: boolean;
	onClose: () => void;
	deputies: Array<IDeputyMap>;
}

export const CompleteDeputiesModal: FC<CompleteDeputiesModalProps> = ({
	isOpen,
	onClose,
	deputies,
}) => {
	/** Context */
	const { userId } = useAuthContext();

	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Hooks */
	const qc = useQueryClient();
	const latestDeputy = deputies.at(0);

	/** Functions */
	const handleFinishDeputy = async () => {
		try {
			setIsLoading(true);
			await ax.patch(`/v2/employees/${userId}/deputies/${latestDeputy?.id}/finish`);
		} catch (error) {
			console.error(error);
		} finally {
			await qc.refetchQueries({ queryKey: ["unfinished-deputies"] });
			onClose();
			setIsLoading(false);
		}
	};

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Vertretung beenden</ModalHeader>
				<ModalBody>
					Sie haben eine Vertretung vom{" "}
					<strong>{moment(latestDeputy?.startDate).format("L")}</strong> bis{" "}
					<strong>{moment(latestDeputy?.endDate).format("L")}</strong> eingestellt. Möchten Sie
					diese jetzt beenden und alle Beteiligten benachrichtigen?
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							isLoading={isLoading}
							onClick={handleFinishDeputy}
						>
							Vertretung beenden
						</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
