import { useSalesStore } from "@/sites/sales/sales/store";
import { FormControl, FormLabel, Input, Select, Textarea, VStack } from "@msuite/picasso";
import { useFormContext, useWatch } from "react-hook-form";
import { FormError, type ProjectRequestLoseReasonFormSchema } from "./schema";

export const ProjectRequestLoseReasonForm = () => {
	/** Hooks */
	const methods = useFormContext<ProjectRequestLoseReasonFormSchema>();
	const salesStore = useSalesStore();
	const [currentReason] = useWatch({ control: methods.control, name: ["loseReason"] });

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Grund</FormLabel>
				<Select
					{...methods.register("loseReason")}
					placeholder="Bitte auswählen"
				>
					{salesStore.salesConfiguration?.projectLoseReasons.map((reason) => (
						<option
							key={reason.id}
							value={reason.id}
						>
							{reason.name}
						</option>
					))}
					<hr />
					<option
						key="misc"
						value="misc"
					>
						Sonstiges
					</option>
				</Select>
				<FormError name="loseReason" />
			</FormControl>
			{currentReason === "misc" && (
				<FormControl>
					<FormLabel>Erläuterung (Sonstiges)</FormLabel>
					<Input
						{...methods.register("loseReasonMisc")}
						placeholder="Erläuterung"
					/>
					<FormError name="loseReasonMisc" />
				</FormControl>
			)}
			<FormControl>
				<FormLabel>Beschreibung</FormLabel>
				<Textarea
					{...methods.register("loseDescription")}
					placeholder="Beschreibung"
				/>
				<FormError name="loseDescription" />
			</FormControl>
		</VStack>
	);
};
