import type { ProjectRequestTimelineActivityEntry } from "@msuite/katana";
import { Text, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { BiSolidChevronUp, BiSolidChevronsUp } from "react-icons/bi";
import { TbCircleDashed } from "react-icons/tb";

/** Props Interface */
interface TimelineActivityPriorityProps {
	activity: ProjectRequestTimelineActivityEntry;
}

export const TimelineActivityPriority: FC<TimelineActivityPriorityProps> = ({ activity }) => {
	/** Context */
	const { colors } = useUIContext();

	if (activity.priority === "low") {
		return (
			<Text
				color={colors.blue}
				style={{
					transform: "translateY(1px)",
				}}
			>
				<TbCircleDashed />
			</Text>
		);
	}
	if (activity.priority === "medium") {
		return (
			<Text
				color={colors.orange}
				style={{
					transform: "translateY(1px)",
				}}
			>
				<BiSolidChevronUp />
			</Text>
		);
	}
	if (activity.priority === "high") {
		return (
			<Text
				color={colors.red}
				style={{
					transform: "translateY(1px)",
				}}
			>
				<BiSolidChevronsUp />
			</Text>
		);
	}
};
