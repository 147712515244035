import type { ZodSatisfy } from "@/util/zod";
import { type ProjectRequest, exp_cleanUndefinedValues, generateId, moment } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import { z } from "zod";

export const projectRequestFormSchema = z
	.object({
		id: z.string().default(() => generateId()),
		state: z.string().default(() => "pending"),
		receiptDate: z.string().default(() => moment().format("YYYY-MM-DD")),
		clientType: z.string({ required_error: "Bitte auswählen" }).min(1, "Bitte auswählen"),
		clientId: z.string().optional(),
		clientName: z.string().optional(),
		submissionDate: z.string().default(() => moment().add(2, "week").format("YYYY-MM-DD")),
		medium: z.union([z.literal("email"), z.literal("post"), z.literal("digital-platform")]),
		projectName: z.string().min(1, "Bitte geben Sie einen Projektbezeichnung an"),
		street: z.string().min(1, "Bitte geben Sie eine Straße an"),
		houseNumber: z.string().min(1, "Bitte geben Sie eine Hausnummer an"),
		zipCode: z.string().min(1, "Bitte geben Sie eine PLZ an"),
		city: z.string().min(5, "Bitte geben Sie eine Ortsangabe an"),
		file: z.string().optional(),
		files: z.array(z.string()).optional(),

		owner: z.string().min(1, "Bitte einen Besitzer auswählen"),
		coOwners: z
			.array(z.string())
			.transform((value) => value.filter((arg) => !!arg))
			.optional()
			.default(() => []),

		createdAt: z.string().default(() => moment().toISOString()),
		updatedAt: z
			.string()
			.default(() => moment().toISOString())
			.optional(),
		createdBy: z.string().optional(),
		updatedBy: z.string().optional(),
		stateChangedAt: z.string().optional(),
		stateChangedBy: z.string().optional(),
		stateTracking: z.object({}).optional(),
		finalResult: z.string().optional(),
		loseDescription: z.string().optional(),
		loseReason: z.string().optional(),
		loseReasonMisc: z.string().optional(),
	} satisfies ZodSatisfy<ProjectRequest>)
	.superRefine((value, ctx) => {
		if (!value.clientId && !value.clientName) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Bitte wählen Sie einen Auftraggeber aus",
				path: ["clientId"],
			});
		}
		if (moment(value.receiptDate).isAfter(moment(value.submissionDate))) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Abgabedatum muss nach Empfangsdatum liegen",
				path: ["submissionDate"],
			});
		}
		return;
	})
	.transform(exp_cleanUndefinedValues);

export type ProjectRequestFormType = z.infer<typeof projectRequestFormSchema>;
export const FormError = FormErrorMessage<ProjectRequestFormType>;
