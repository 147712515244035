import { storage } from "@/core";
import {
	Box,
	Center,
	IconButton,
	Tooltip,
	useDisclosure,
	useFileStorage,
	useHover,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useRef } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import { TbTrash } from "react-icons/tb";
import { DocumentFile } from "./document-file";
import { FileImage } from "./file-image";
import { DeleteFileModal } from "./modals";

/** Props Interface */
interface FileCardUploadedProps {
	fileName: string;
	path: string;
	withoutDelete?: boolean;
	onSelect?: (fileName: string) => void;
	isSelected?: boolean;
}

export const FileCardUploaded: FC<FileCardUploadedProps> = ({
	fileName,
	path,
	withoutDelete,
	onSelect,
	isSelected,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { downloadURL, metadata, isImage, fileRef, imageSize } = useFileStorage(storage, {
		fileName,
		path,
	});
	const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
	const { isHovered } = useHover(ref);
	const deleteModal = useDisclosure();

	/** Functions */
	function handleOpenDocument() {
		window.open(downloadURL, "_blank");
	}

	const hasSelectFn = !!onSelect;

	/** Render */
	return (
		<Tooltip
			label={metadata?.name}
			openDelay={250}
		>
			<Center
				ref={ref}
				borderRadius="lg"
				borderWidth={0.5}
				position="relative"
				overflow="hidden"
				backgroundColor={colors.whiteAdjusted}
				style={{ aspectRatio: "1", width: "100%" }}
				cursor="pointer"
				onClick={
					hasSelectFn
						? () => {
								onSelect(fileName);
							}
						: isImage
							? undefined
							: handleOpenDocument
				}
			>
				{isImage ? (
					<FileImage
						src={downloadURL}
						imageSize={imageSize}
						hasSelectFn={hasSelectFn}
					/>
				) : (
					<DocumentFile
						src={downloadURL}
						metadata={metadata}
						hasSelectFn={hasSelectFn}
					/>
				)}
				{isHovered && !withoutDelete && (
					<IconButton
						position="absolute"
						top="0"
						right="0"
						variant="ghost"
						aria-label="delete"
						colorScheme="red"
						size="sm"
						rounded="full"
						icon={<TbTrash />}
						zIndex={9999}
						onClick={(e) => {
							e.stopPropagation();
							deleteModal.onOpen();
						}}
					/>
				)}
				{isSelected && (
					<Box
						width="100%"
						height="100%"
						padding={2}
						borderWidth={2}
						borderColor="blue.500"
						rounded="lg"
						position="absolute"
						left="0"
						top="0"
						display="flex"
						flexDirection="column"
						alignItems="flex-end"
					>
						<Box
							color="blue.500"
							rounded="100%"
							backgroundColor="white"
						>
							<HiBadgeCheck />
						</Box>
					</Box>
				)}
				<DeleteFileModal
					isOpen={deleteModal.isOpen}
					onClose={deleteModal.onClose}
					fileRef={fileRef}
				/>
			</Center>
		</Tooltip>
	);
};
