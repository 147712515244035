import { ax } from "@/core";
import { ProjectRequestNoteForm } from "@/forms/project-request-note-form";
import {
	type ProjectRequestNoteFormType,
	projectRequestNoteFormSchema,
} from "@/forms/project-request-note-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProjectRequestNoteModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
}

export const AddProjectRequestNoteModal: FC<AddProjectRequestNoteModalProps> = ({
	isOpen,
	onClose,
	projectRequestId,
}) => {
	/** Hooks */
	const methods = useForm<ProjectRequestNoteFormType>({
		resolver: zodResolver(projectRequestNoteFormSchema),
	});

	/** Functions */
	async function handleOnSubmit(values: ProjectRequestNoteFormType) {
		await ax.post(`/v2/project-requests/${projectRequestId}/note`, {
			data: { values },
		});
		handleOnClose();
	}

	async function handleOnReject(errors: typeof methods.formState.errors) {
		console.error(errors);
		toast.error("Fehlgeschlagen");
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Neue Notiz erstellen</ModalHeader>
						<ModalBody>
							<ProjectRequestNoteForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									onClick={handleOnClose}
									variant="ghost"
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={methods.formState.isSubmitting}
								>
									Absenden
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
