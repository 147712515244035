import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import { HStack, Heading, Spacer, Text, VStack, useDocument, useUIContext } from "@msuite/picasso";
import type { FC } from "react";
import { TbExternalLink } from "react-icons/tb";

/** Props Interface */
interface ConstructionSiteHeaderMinProps {
	constructionSiteId: string | undefined;
	subscribe?: boolean;
}

export const ConstructionSiteHeaderMin: FC<ConstructionSiteHeaderMinProps> = ({
	constructionSiteId,
	subscribe = false,
}) => {
	/** Context */
	const { colors } = useUIContext();
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${constructionSiteId}`,
		queryClientOptions: {
			enabled: !!constructionSiteId,
			staleTime: Number.POSITIVE_INFINITY,
		},
		subscribe,
	});

	/** Functions */
	function handleRedirect() {
		window.open(`/baustellen/${constructionSiteId}`, "_blank");
	}

	if (!constructionSite) return null;

	/** Render */
	return (
		<VStack
			spacing={1}
			backgroundColor={colors.whiteAdjusted}
			rounded="xl"
			px={4}
			py={4}
			borderWidth={1}
		>
			<HStack
				spacing={2}
				cursor="pointer"
				onClick={handleRedirect}
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Heading size="md">{constructionSite?.bauvorhaben}</Heading>
				<TbExternalLink />
				<Spacer />
				<Text>{constructionSite?.nummer}</Text>
			</HStack>
			<Text color={colors.gray}>
				{constructionSite?.strasse} {constructionSite?.hausnummer}, {constructionSite?.plz}{" "}
				{constructionSite?.ort}
			</Text>
		</VStack>
	);
};
