import {
	type ProjectRequestTimelineNoteEntry,
	findMentionNames,
	moment,
	removeMentions,
} from "@msuite/katana";
import {
	FormControl,
	FormLabel,
	Highlight,
	Label,
	Text,
	useAuthContext,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { NoteMenu } from "./note-menu";

/** Props Interface */
interface TimelineNoteEntryProps {
	entry: ProjectRequestTimelineNoteEntry & { id: string };
	projectRequestId: string;
}

export const TimelineNoteEntry: FC<TimelineNoteEntryProps> = ({ entry, projectRequestId }) => {
	/** Context */
	const { colors } = useUIContext();
	const { userId } = useAuthContext();

	/** Computed */
	const mentionNames = findMentionNames(entry.note);

	/** Render */
	return (
		<Fragment>
			{userId === entry.createdBy && (
				<NoteMenu
					projectRequestId={projectRequestId}
					entry={entry}
				/>
			)}
			{entry.updatedAt && (
				<Label>Bearbeitet am {moment(entry.updatedAt).format("dddd, L [um] HH:mm [Uhr]")}</Label>
			)}
			<FormControl>
				<FormLabel>Notiz</FormLabel>
				<Text whiteSpace="pre-line">
					<Highlight
						query={mentionNames}
						styles={{
							color: colors.blue,
							opacity: 0.75,
							":hover": {
								cursor: "pointer",
								opacity: 1,
							},
						}}
					>
						{removeMentions(entry.note)}
					</Highlight>
				</Text>
			</FormControl>
		</Fragment>
	);
};
