import { ax, db } from "@/core";
import { type ConfigurationFileSales, type SalesColumn, generateId } from "@msuite/katana";
import { toast } from "@msuite/picasso";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { create } from "zustand";

interface ISalesStore {
	searchValue: string;
	setSearchValue: (value: string) => void;
	filterState: "all" | "public" | "private";
	setFilterState: (value: "all" | "public" | "private") => void;
	orderDirection: "asc" | "desc";
	setOrderDirection: (value: "asc" | "desc") => void;

	isEditColumnsMode: boolean;
	toggleEditColumnsMode: () => void;

	configurationIsEdited: boolean;
	setConfigurationIsEdited: (value: boolean) => void;

	salesConfiguration: ConfigurationFileSales | null;
	setSalesConfiguration: (value: ConfigurationFileSales | null) => void;

	removeColumnFromSalesConfiguration: (columnId: string) => void;
	addColumnToSalesConfiguration: (insertIndex: number) => void;

	moveRequestsMap: {
		from: string;
		to: string;
	}[];
	resetMoveRequestMap: () => void;
	addColumnToMoveRequestMap: (columnId: string, targetId: string) => void;

	removeReasonFromSalesConfiguration: (reasonId: string) => void;
	renameReasonInSalesConfiguration: (reasonId: string, newName: string) => void;

	handleDiscardConfigurationChanges: (callback?: () => void) => Promise<void>;
	handleSaveConfiguration: (callback: (() => void) | undefined) => Promise<void>;
	handleMoveProjectRequests: () => Promise<void>;

	getSalesColumn: (columnId: string) => SalesColumn | null;
}

export const useSalesStore = create<ISalesStore>((set, get) => ({
	/**
	 * This is the search value.
	 */
	searchValue: "",

	/**
	 * This function sets the searchValue state to the given value.
	 * @param value
	 * @returns
	 */
	setSearchValue: (value) => set({ searchValue: value }),

	/**
	 * This is the filter state.
	 */
	filterState: "all",

	/**
	 * This function sets the filter state to the given value.
	 * @param value
	 * @returns
	 */
	setFilterState: (value) => set({ filterState: value }),

	/**
	 * This is the order direction of the project requests.
	 */
	orderDirection: "asc",

	/**
	 * This function sets the order direction to the given value.
	 * @param value
	 * @returns
	 */
	setOrderDirection: (value) => set({ orderDirection: value }),

	/**
	 * This is the state that indicates whether the columns are being edited.
	 */
	isEditColumnsMode: false,

	/**
	 * This function toggles the isEditColumnsMode state.
	 * @returns
	 */
	toggleEditColumnsMode: () => set((state) => ({ isEditColumnsMode: !state.isEditColumnsMode })),

	/**
	 * This is the sales configuration. It is null before the configuration is fetched from the server.
	 */
	salesConfiguration: null,

	/**
	 * This function sets the salesConfiguration state to the given value.
	 * @param value
	 * @returns
	 */
	setSalesConfiguration: (value) => set({ salesConfiguration: value }),

	/**
	 * This is the state that indicates whether the configuration is edited.
	 */
	configurationIsEdited: false,

	/**
	 * This function sets the configurationIsEdited state to the given value.
	 * @param value
	 * @returns
	 */
	setConfigurationIsEdited: (value) => set({ configurationIsEdited: value }),

	/**
	 * This function removes a column from the sales configuration.
	 * @param columnId
	 */
	removeColumnFromSalesConfiguration: (columnId: string) => {
		return set((state) => {
			if (!state.salesConfiguration) return state;
			const currentColumn = state.salesConfiguration.columns.find(
				(column) => column.id === columnId,
			);
			if (!currentColumn) return state;
			state.salesConfiguration.columns = state.salesConfiguration.columns.filter(
				(column) => column.id !== columnId,
			);
			state.salesConfiguration.archivedColumns.push(currentColumn);
			return { ...state };
		});
	},

	/**
	 * This function adds a new column to the sales configuration.
	 * @param insertIndex
	 * @returns
	 */
	addColumnToSalesConfiguration: (insertIndex: number) => {
		return set((state) => {
			if (!state.salesConfiguration) return state;
			const newColumns = state.salesConfiguration?.columns ?? [];
			newColumns.splice(insertIndex, 0, {
				id: generateId(),
				name: "Neue Spalte",
				iconName: "circle",
				iconColor: "gray",
			});
			state.salesConfiguration.columns = newColumns;
			return { ...state };
		});
	},

	/**
	 * This is the move requests map. It contains information about which cards should be moved to a different column when the user clicks on save.
	 */
	moveRequestsMap: [],

	/**
	 * This function resets the move requests map.
	 */
	resetMoveRequestMap: () => set({ moveRequestsMap: [] }),

	/**
	 * This function adds a column to the move requests map.
	 * @param columnId
	 * @param targetId
	 * @returns
	 */
	addColumnToMoveRequestMap: (columnId: string, targetId: string) => {
		return set((state) => {
			const currentFroms = state.moveRequestsMap.map((item) => item.from);
			if (currentFroms.includes(targetId)) {
				toast.error("Die auswählte Spalte wurde bereits gelöscht.");
				return state;
			}
			state.moveRequestsMap.push({ from: columnId, to: targetId });
			return { ...state };
		});
	},

	/**
	 * This function removes a reason from the sales configuration.
	 * @param reasonId
	 * @returns
	 */
	removeReasonFromSalesConfiguration: (reasonId: string) => {
		return set((state) => {
			if (!state.salesConfiguration) return state;
			const currentReasons = state.salesConfiguration.projectLoseReasons;
			const selectedReason = currentReasons.find((reason) => reason.id === reasonId);
			if (!selectedReason) return state;
			const newReasons = currentReasons.filter((reason) => reason.id !== reasonId);
			state.salesConfiguration.projectLoseReasons = newReasons;
			state.salesConfiguration.archivedProjectLoseReasons.push(selectedReason);
			return { ...state };
		});
	},

	/**
	 * This function renames a reason in the sales configuration.
	 * @param reasonId
	 * @param newName
	 * @returns
	 */
	renameReasonInSalesConfiguration: (reasonId: string, newName: string) => {
		return set((state) => {
			if (!state.salesConfiguration) return state;
			const currentReasons = state.salesConfiguration.projectLoseReasons;
			const selectedReason = currentReasons.find((reason) => reason.id === reasonId);
			if (!selectedReason) return state;
			selectedReason.name = newName;
			return { ...state };
		});
	},

	/**
	 * This function handles the discarding of the configuration changes.
	 * @param callback
	 * @returns
	 */
	handleDiscardConfigurationChanges: async (callback?: () => void) => {
		try {
			const salesStore = get();
			const configurationRef = doc(db, "_configuration/sales");
			const configuration: ConfigurationFileSales = (
				await getDoc(configurationRef)
			).data() as ConfigurationFileSales;
			salesStore.setSalesConfiguration(configuration);
			salesStore.resetMoveRequestMap();
			salesStore.setConfigurationIsEdited(false);
			callback?.();
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * This function handles the saving of the configuration.
	 * @param callback
	 */
	handleSaveConfiguration: async (callback: (() => void) | undefined) => {
		try {
			const salesStore = get();
			const configurationRef = doc(db, "_configuration/sales");
			const newConfiguration = salesStore.salesConfiguration;
			await setDoc(configurationRef, newConfiguration);
			await salesStore.handleMoveProjectRequests();
			salesStore.resetMoveRequestMap();
			callback?.();
			toast.success("Konfiguration gespeichert");
		} catch (error) {
			console.error(error);
			toast.error("Konfiguration konnte nicht gespeichert werden");
		}
	},

	/**
	 * This function handles the moving of project requests.
	 * @param callback
	 */
	handleMoveProjectRequests: async () => {
		try {
			const salesStore = get();
			const moveRequestMap = salesStore.moveRequestsMap;
			const promises: Promise<void>[] = [];
			for (const mapItem of moveRequestMap) {
				await ax.patch(
					`/v2/project-requests/batch-move/state?from=${mapItem.from}&to=${mapItem.to}`,
				);
			}
			await Promise.all(promises);
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * This function returns the sales column with the given columnId.
	 * @param columnId
	 * @returns
	 */
	getSalesColumn: (columnId: string) => {
		const salesStore = get();
		const allColumns = (salesStore.salesConfiguration?.columns ?? []).concat(
			salesStore.salesConfiguration?.archivedColumns ?? [],
		);

		const column = allColumns.find((column) => column.id === columnId);
		if (!column) return null;
		return column;
	},
}));
