import { DropzoneModalContext } from "@/context/dropzone-modal";
import { bytesToMegabyteString } from "@msuite/katana";
import { HStack, IconButton, Spacer, Text, useUIContext } from "@msuite/picasso";
import { type FC, useCallback, useContext } from "react";
import { IoTrash } from "react-icons/io5";
import { MimeTypeIcon } from "./mime-type-icon";

/** Props Interface */
interface FileProps {
	file: File;
	index: number;
}

export const FileCard: FC<FileProps> = ({ file, index }) => {
	/** Context */
	const { onDelete } = useContext(DropzoneModalContext);
	const { colors } = useUIContext();
	const { whiteAdjusted } = colors;

	/** Callbacks */
	const handleDelete = useCallback(() => {
		return onDelete?.(file);
	}, [file, onDelete]);

	/** Render */
	return (
		<HStack
			justifyContent="flex-start"
			width="100%"
			borderWidth={1}
			rounded="md"
			py="0.5rem"
			pl="0.75rem"
			pr="0.5rem"
			bg={whiteAdjusted}
			spacing={3}
			cursor="default"
		>
			<MimeTypeIcon
				type={file.type}
				index={index}
			/>
			<Text fontSize="md">{file.name}</Text>
			<Spacer />
			<Text
				fontSize="sm"
				color="gray.500"
			>
				{bytesToMegabyteString(file.size)}
			</Text>
			<IconButton
				rounded="md"
				size="xs"
				variant="ghost"
				colorScheme="red"
				icon={<IoTrash />}
				aria-label="delete file"
				onClick={handleDelete}
			/>
		</HStack>
	);
};
