import { DropzoneModalContext } from "@/context/dropzone-modal";
import { HStack, Progress, Text } from "@msuite/picasso";
import { type FC, useContext } from "react";

export const UploadProgress: FC = () => {
	/** Context */
	const { progress, files } = useContext(DropzoneModalContext);

	/** Render */
	return (
		<HStack
			width="100%"
			spacing={6}
		>
			<Text
				whiteSpace="nowrap"
				fontSize="xs"
			>
				{progress.progress}%
			</Text>
			<Progress
				value={progress.progress}
				size="md"
				rounded="sm"
				width="100%"
				colorScheme="brand"
				hasStripe
				isAnimated
			/>
			<Text
				whiteSpace="nowrap"
				fontSize="xs"
			>
				Datei: {progress.file_nr + 1 > files.length ? files.length : progress.file_nr + 1} /{" "}
				{files?.length || 1}
			</Text>
		</HStack>
	);
};
