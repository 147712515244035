import { ax } from "@/core";
import {
	type CampaignInvitationModalFormType,
	campaignInvitationModalFormSchema,
} from "@/forms/campaign-invitation-modal-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CampaignInvitationModalForm } from "../../../forms/campaign-invitation-modal-form";

/** Props Interface */
interface AddCampaignInvitationModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddCampaignInvitationModal: FC<AddCampaignInvitationModalProps> = ({
	isOpen,
	onClose,
}) => {
	/** Hooks */
	const methods = useForm<CampaignInvitationModalFormType>({
		resolver: zodResolver(campaignInvitationModalFormSchema),
	});

	async function onSubmit(values: CampaignInvitationModalFormType) {
		try {
			await ax.post("/v2/email-service/campaigns/christmas-2024/invite", {
				data: {
					values,
				},
			});
			methods.reset();
			onClose();
		} catch (error) {
			toast.error("Ein Fehler ist aufgetreten.");
		}
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Person einladen</ModalHeader>
						<ModalBody>
							<CampaignInvitationModalForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									isLoading={methods.formState.isSubmitting}
									type="submit"
								>
									Absenden
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
