import { ax, db } from "@/core";
import type { ConfigurationFileSales } from "@msuite/katana";
import { useApi, useDocument } from "@msuite/picasso";
import { useEffect } from "react";
import { useSalesStore } from "./store";

export function useSalesConfiguration(subscribe = true) {
	const salesStore = useSalesStore();

	/** Initial */
	const { isFetched } = useApi(ax, {
		url: "/v2/project-requests/configuration",
		dependencies: ["sales", "configuration"],
	});

	/** Hooks */
	const { data: configuration } = useDocument<ConfigurationFileSales>(db, {
		path: "_configuration/sales",
		subscribe,
		queryClientOptions: {
			enabled: isFetched,
		},
	});

	useEffect(() => {
		if (!configuration) return;
		salesStore.setSalesConfiguration(configuration);
	}, [configuration]);

	return isFetched;
}
