import type { ProjectRequestTimelineFileDeleteEntry } from "@msuite/katana";
import { HStack, Text } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TimelineFileDeleteEntryProps {
	projectRequestId: string;
	entry: ProjectRequestTimelineFileDeleteEntry;
}

export const TimelineFileDeleteEntry: FC<TimelineFileDeleteEntryProps> = ({
	entry: { fileName },
}) => {
	/** Render */
	return (
		<HStack justifyContent="space-between">
			<Text>
				Es wurde das Dokument <strong>{fileName}</strong> gelöscht.
			</Text>
		</HStack>
	);
};
