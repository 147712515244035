import { useAppContext } from "@/context/app";
import { ax, db } from "@/core";
import { ConstructionSiteForm, useConstructionSiteForm } from "@/forms/construction-site-form";
import { ConstructionSiteSchema } from "@/forms/construction-site-form/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	type ConstructionSite,
	type ConstructionSiteContact,
	generateId,
	wait,
} from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalTitle,
	SegmentedControl,
	VStack,
	findFirstErrorMessage,
	toast,
	useAuthContext,
	useCollection,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import { type FC, useEffect, useId, useRef, useState } from "react";
import { FormProvider, useForm, type useFormState } from "react-hook-form";
import { addConstructionSiteToWeekly } from "util/construction-site";
import { ExistingSearchBox } from "./existing-search-box";

/** Props Interface */
interface AddConstructionSiteModalProps {
	isOpen: boolean;
	onClose: () => void;
	withExistingSearchBox?: boolean;
	onComplete?: (constructionSite: ConstructionSite) => Promise<void> | void;
	shouldAddToWeek?: boolean;
	initialValues?: Partial<ConstructionSite>;
}

export const defaultValuesConstructionSite: ConstructionSite = {
	nummer: "",
	bauvorhaben: "",
	strasse: "",
	hausnummer: "",
	plz: "",
	isInactive: false,
	ort: "",
	auftraggeber: "",
	plangruppe: "",
	note: "",
	bauleiter: [],
	currentDeputies: [],
	tickets: [],
	inactive: [],
};

export const AddConstructionSiteModal: FC<AddConstructionSiteModalProps> = ({
	isOpen,
	onClose,
	onComplete,
	withExistingSearchBox,
	shouldAddToWeek,
	initialValues,
}) => {
	/** State */
	const [generateNumber, setGenerateNumber] = useState<boolean>(false);

	/** Context */
	const { activeWorkspace } = useAppContext();
	const { user, validation } = useAuthContext();

	/** Hooks */
	const renderId = useId();
	const modalRef = useRef(null);
	const defaultValues = {
		...defaultValuesConstructionSite,
		plangruppe: activeWorkspace ?? "menke",
		...initialValues,
	};
	const methods = useForm<ConstructionSite>({
		resolver: yupResolver(ConstructionSiteSchema) as any,
		reValidateMode: "onSubmit",
		defaultValues,
	});
	const { SEGMENTED_CONTROL_OPTIONS, currentView, setCurrentView, DEFAULT_VIEW } =
		useConstructionSiteForm({
			withExistingSearchBox,
			isOpen,
			methods,
		});
	const { setValue, getValues } = methods;
	const currentConstructionSiteId = methods.watch("id");
	const [placeholderConstructionSiteId, setPlaceholderConstructionSiteId] = useState<string>(
		generateId(),
	);

	/** Prefetch */
	const { isFetched: prefetchIsFetched } = useCollection<ConstructionSiteContact>(db, {
		path: "_contactBook",
		orderBy: "name",
		subscribe: false,
	});

	/** Effects */
	useEffect(() => {
		if (!user) return;
		if (!validation?.isBauleiter) return;
		const values = getValues();
		if (values.bauleiter?.length) return;
		setValue("bauleiter", [user.id]);
	}, [user, validation, setValue, getValues]);

	/** Functions */
	async function handleOnClose() {
		handleReset();
		onClose();
	}

	async function handleOnSubmit(values: ConstructionSite) {
		try {
			if (!values.nummer) setGenerateNumber(true);
			const shouldGenerateNumber = generateNumber || !values.nummer;
			if (values.id) {
				const constructionSiteRef = doc(db, `baustellen/${values.id}`);
				await updateDoc(constructionSiteRef, { ...values });
			} else {
				values.id = placeholderConstructionSiteId;
				await ax.post(`/v2/construction-site?generateNumber=${shouldGenerateNumber}`, {
					data: { values },
				});
			}
			if (shouldAddToWeek) await addConstructionSiteToWeekly(values, activeWorkspace);
			await wait(300);
			await onComplete?.(values);
			handleOnClose();
		} catch (error) {
			toast.error("Fehler beim Erstellen der Baustelle");
			console.error(error);
		}
	}

	function handleReset() {
		setCurrentView(DEFAULT_VIEW);
		methods.reset({
			...defaultValues,
			bauleiter: user?.id ? [user?.id] : [],
		});
		setGenerateNumber(false);
	}

	function handleSelectExisting(constructionSite: ConstructionSite) {
		try {
			methods.reset(constructionSite);
			setCurrentView("tickets");
		} catch (error) {
			console.error(error);
		}
	}

	async function onReject(e: ReturnType<typeof useFormState>["errors"]) {
		const firstErrorMessage = findFirstErrorMessage(e);
		toast.error(firstErrorMessage);
	}

	/** Effects */
	useEffect(() => {
		setPlaceholderConstructionSiteId(generateId());
	}, [isOpen]);

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, onReject)}>
					<ModalContent
						maxW="60rem"
						minH="55vh"
						maxH="75vh"
						flexGrow={0}
					>
						<ModalCloseButton />
						<ModalHeader>
							<VStack>
								<ModalTitle>Baustelle hinzufügen</ModalTitle>
								<SegmentedControl
									options={SEGMENTED_CONTROL_OPTIONS}
									value={currentView}
									onChange={setCurrentView}
								/>
							</VStack>
						</ModalHeader>
						<ModalBody
							flex="1"
							position="relative"
						>
							{currentView === "existing-search-box" ? (
								<ExistingSearchBox
									onSelect={handleSelectExisting}
									renderId={renderId}
								/>
							) : isOpen && prefetchIsFetched ? (
								<ConstructionSiteForm
									modalRef={modalRef}
									currentView={currentView}
									generateNumber={generateNumber}
									setGenerateNumber={setGenerateNumber}
									isEdit={currentConstructionSiteId !== undefined}
									constructionSiteId={currentConstructionSiteId ?? placeholderConstructionSiteId}
								/>
							) : undefined}
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								{currentView !== "existing-search-box" && (
									<Button
										isLoading={methods.formState.isSubmitting}
										type="submit"
									>
										Hinzufügen
									</Button>
								)}
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
