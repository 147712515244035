import { LinkFilesModal } from "@/modals/construction-sites/link-files-modal";
import { FileGalleryModal } from "@/modals/upload/file-gallery-modal";
import type { Ticket, TicketEstimationUnit, TicketTypes } from "@msuite/katana";
import { moment, wait } from "@msuite/katana";
import {
	Box,
	ButtonGroup,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem,
	IconButton,
	Input,
	Label,
	Select,
	Textarea,
	useDisclosure,
	useToken,
	useUIContext,
} from "@msuite/picasso";
import { createCustomMediaMetadata } from "components/card";
import { AssignTicketDatePopover } from "components/popover";
import type { TicketDateForm } from "components/popover/assign-ticket-date-popover/schema";
import type { UploadResult } from "firebase/storage";
import { motion } from "framer-motion";
import { useLayoutEffect } from "react";
import type { FieldValues, UseFormReturn, useFormContext } from "react-hook-form";
import { IoChevronForward } from "react-icons/io5";
import { TbCopy, TbPaperclip, TbTrashFilled } from "react-icons/tb";
import { FormError } from "./schema";

export type TicketFormType = "active" | "inactive";
const ticketTypes: TicketTypes[] = ["Auf", "Ab", "Um", "Anlieferung", "Abholung", "Hinweis"];
const esitimationUnitTypes: TicketEstimationUnit[] = ["hours", "days"];
const estimationUnitLabels: Record<TicketEstimationUnit, string> = {
	days: "Tage",
	hours: "Stunden",
};

/** Props Interface */
interface TicketFormProps {
	index?: number;
	formPrefix?: string;
	isInactive?: boolean;
	methods: ReturnType<typeof useFormContext<FieldValues>> | UseFormReturn<Ticket, any, undefined>;
	onDelete?: (formPrefix: string) => void;
	onCopy?: (formPrefix: string) => void;
	forceExpand?: boolean;
	withFirstLineLabels?: boolean;
	modalRef?: React.RefObject<HTMLDivElement>;
	initialFocusTicketId?: string;
	constructionSiteId: string | undefined;
}

async function handleInitialFocus(ticketId: string | undefined) {
	if (!ticketId) return;
	await wait(100);
	const element = document.getElementById(`${ticketId}-ticket-field`);
	if (!element) return;
	element.focus();
}

const DATE_FORMAT_LONG = "dd, DD.MM.YYYY";
const DATE_FORMAT_MEDIUM = "DD.MM.YYYY";
const DATE_FORMAT_SHORT = "DD.MM.";
const WEEK_FORMAT = "[KW] WW";

export function TicketForm({
	formPrefix,
	onDelete,
	onCopy,
	methods,
	isInactive,
	forceExpand,
	withFirstLineLabels,
	initialFocusTicketId,
	constructionSiteId,
}: TicketFormProps) {
	/** Context */
	const { register, watch, setValue, reset } = methods as ReturnType<
		typeof useFormContext<FieldValues>
	>;

	/** Constants */
	const currentTicket = (formPrefix ? watch(formPrefix) : watch()) as Ticket | undefined;
	const isInitialFocus = initialFocusTicketId === currentTicket?.id;

	/** Hooks */
	const { colors } = useUIContext();
	const additionalInformation = useDisclosure({
		defaultIsOpen: isInitialFocus,
	});
	const hasTrailingAction = onDelete !== undefined || onCopy !== undefined;
	const fileGalleryModal = useDisclosure();
	const fileLinkModal = useDisclosure();

	/** Tokens */
	const ticketColor = useToken("colors", "red.500");

	/** Effects */
	useLayoutEffect(() => {
		if (!isInitialFocus) return;
		handleInitialFocus(currentTicket?.id);
	}, [isInitialFocus, currentTicket?.id]);

	/** Functions */
	function cleanFormPrefix() {
		if (formPrefix?.endsWith(".")) return formPrefix.slice(0, -1);
		return formPrefix ?? "";
	}

	function getRegisterPrefix() {
		return formPrefix ? `${formPrefix}.` : "";
	}

	function handleRemoveTicket() {
		onDelete?.(cleanFormPrefix());
	}

	function handleCopyTicket() {
		onCopy?.(cleanFormPrefix());
	}

	function handleOnRemoveDate() {
		const ticket = { ...currentTicket };
		ticket.date = undefined;
		ticket.date_is_deadline = undefined;
		ticket.date_earliest_from = undefined;
		if (cleanFormPrefix()) {
			setValue(cleanFormPrefix(), ticket);
		} else {
			reset(ticket);
		}
	}

	function handleOnUpdateDate(updateValue: TicketDateForm) {
		const ticket = { ...currentTicket };
		if (updateValue.date !== undefined) ticket.date = updateValue.date;
		else ticket.date = undefined;
		if (updateValue.dateIsDeadline !== undefined)
			ticket.date_is_deadline = updateValue.dateIsDeadline;
		else ticket.date_is_deadline = false;
		if (updateValue.dateEarliestFrom !== undefined)
			ticket.date_earliest_from = updateValue.dateEarliestFrom;
		else ticket.date_earliest_from = undefined;
		if (!updateValue.withEarliestFrom) ticket.date_earliest_from = undefined;
		if (cleanFormPrefix()) {
			setValue(cleanFormPrefix(), ticket);
		} else {
			reset(ticket);
		}
	}

	function handleOnCompleteFileUpload(results: (UploadResult | undefined)[]) {
		try {
			if (!currentTicket) throw new Error("No ticket found");
			const filteredResults = results.filter((result) => result !== undefined);
			const fileNames = filteredResults
				.map((result) => result?.metadata.name)
				.filter((name) => name !== undefined) as string[];
			const newTicket = {
				...currentTicket,
				files: [...(currentTicket.files ?? []), ...fileNames],
			};
			if (cleanFormPrefix()) {
				setValue(cleanFormPrefix(), newTicket);
			} else {
				reset(newTicket);
			}
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnFileDelete(fileName: string) {
		const ticket = { ...currentTicket };
		ticket.files = ticket.files?.filter((file) => file !== fileName);
		if (cleanFormPrefix()) {
			setValue(cleanFormPrefix(), ticket);
		} else {
			reset(ticket);
		}
	}

	function getDateString() {
		const date = moment().convertTicketDateToString(
			currentTicket?.date,
			DATE_FORMAT_LONG,
			WEEK_FORMAT,
		);
		const dateMedium = moment().convertTicketDateToString(
			currentTicket?.date,
			DATE_FORMAT_MEDIUM,
			WEEK_FORMAT,
		);
		const dateEarliestFromShort = moment().convertTicketDateToString(
			currentTicket?.date_earliest_from,
			DATE_FORMAT_SHORT,
			WEEK_FORMAT,
		);
		if (!currentTicket?.date && !currentTicket?.date_earliest_from) return "Datum auswählen";
		if (dateEarliestFromShort && !date) {
			return `ab ${dateEarliestFromShort}`;
		}
		if (date && !dateEarliestFromShort) {
			return date;
		}
		return `${dateEarliestFromShort} - ${dateMedium}`;
	}

	/** Fallback */
	if (!currentTicket) return null;

	const dateLabel = getDateString();

	/** Render */
	return (
		<Grid
			templateColumns={
				hasTrailingAction
					? forceExpand
						? "1.5fr 1fr 3fr max-content"
						: "max-content 1.5fr 1fr 3fr max-content"
					: forceExpand
						? "1.5fr 1fr 3fr"
						: "max-content 1.5fr 1fr 3fr"
			}
			columnGap={4}
			rowGap={4}
		>
			{!forceExpand && (
				<FormControl>
					<FormLabel>&nbsp;</FormLabel>
					<IconButton
						icon={
							<motion.div
								animate={{
									rotate: additionalInformation.isOpen ? 90 : 0,
								}}
								transition={{
									type: "spring",
									stiffness: 260,
									damping: 20,
								}}
							>
								<IoChevronForward />
							</motion.div>
						}
						aria-label="Expand"
						onClick={additionalInformation.onToggle}
					/>
				</FormControl>
			)}
			<AssignTicketDatePopover
				currentTicket={currentTicket}
				onSubmit={handleOnUpdateDate}
				onRemove={handleOnRemoveDate}
				shouldRender
				withLabel={withFirstLineLabels}
				label="Datum"
			>
				<Box cursor="pointer !important">
					<FormControl>
						<FormLabel>Datum</FormLabel>
						<Input
							value={dateLabel}
							onFocus={(e) => e.stopPropagation()}
							isDisabled
							opacity="1 !important"
							cursor="pointer !important"
							pointerEvents="none"
						/>
					</FormControl>
				</Box>
			</AssignTicketDatePopover>
			<FormControl>
				{withFirstLineLabels && <FormLabel>Typ</FormLabel>}
				<Select
					{...register(`${getRegisterPrefix()}type`)}
					isDisabled={isInactive}
					_disabled={{ opacity: 1 }}
				>
					{ticketTypes.map((ticketType) => (
						<option
							key={ticketType}
							value={ticketType}
						>
							{ticketType}
						</option>
					))}
				</Select>
			</FormControl>
			<GridItem colSpan={forceExpand ? 2 : 1}>
				<FormControl>
					{withFirstLineLabels && <FormLabel>Bezeichnung</FormLabel>}
					<Input
						id={`${currentTicket.id}-ticket-field`}
						{...register(`${getRegisterPrefix()}ticket`)}
						_disabled={{ opacity: 1 }}
						placeholder="z.B. SL100 Hofseite"
					/>
					<FormErrorMessage name={`${getRegisterPrefix()}ticket`} />
				</FormControl>
			</GridItem>
			{hasTrailingAction && (
				<FormControl>
					<Label mb="2px">&nbsp;</Label>
					<ButtonGroup
						spacing={4}
						alignSelf="flex-start"
					>
						<IconButton
							icon={<TbCopy />}
							aria-label="Ticket kopieren"
							colorScheme="red"
							onClick={handleCopyTicket}
							isDisabled={!onCopy}
						/>
						<IconButton
							icon={<TbTrashFilled color={ticketColor} />}
							aria-label="Ticket löschen"
							colorScheme="red"
							isDisabled={!onDelete}
							onClick={handleRemoveTicket}
						/>
					</ButtonGroup>
				</FormControl>
			)}
			{(additionalInformation.isOpen || forceExpand) && (
				<>
					<GridItem
						colStart={2 - (forceExpand ? 1 : 0)}
						colSpan={2}
					>
						<FormControl>
							<FormLabel>Geschätzte Zeit</FormLabel>
							<Input
								step={0.5}
								min={0}
								type="number"
								{...register(`${getRegisterPrefix()}estimation.estimatedTime`, {
									valueAsNumber: true,
								})}
								placeholder="z.B. 2"
							/>
						</FormControl>
					</GridItem>
					<GridItem
						colStart={4 - (forceExpand ? 1 : 0)}
						colSpan={2}
					>
						<FormControl>
							<FormLabel>Einheit</FormLabel>
							<Select
								{...register(`${getRegisterPrefix()}estimation.estimationUnit`)}
								placeholder="Einheit auswählen"
								defaultValue={esitimationUnitTypes[0]}
							>
								{esitimationUnitTypes.map((estimationUnitType) => (
									<option
										key={estimationUnitType}
										value={estimationUnitType}
									>
										{estimationUnitLabels[estimationUnitType]}
									</option>
								))}
							</Select>
							<FormErrorMessage name={`${getRegisterPrefix()}estimation.estimationUnit`} />
						</FormControl>
					</GridItem>
					<GridItem
						colStart={2 - (forceExpand ? 1 : 0)}
						colSpan={4}
					>
						<FormControl>
							<FormLabel>Notiz</FormLabel>
							<Textarea
								placeholder="Beliebige Notiz hinzufügen"
								{...register(`${getRegisterPrefix()}note`)}
							/>
						</FormControl>
					</GridItem>
					<GridItem
						colSpan={4}
						colStart={2 - (forceExpand ? 1 : 0)}
					>
						<FormLabel>3D-Modell</FormLabel>
						<Input
							placeholder="https://web.connect.trimble.com/projects/..."
							{...register(`${getRegisterPrefix()}trimbleConnect.0.trimbleLink`)}
						/>
						<FormError name={`${getRegisterPrefix()}trimbleConnect.0.trimbleLink` as any} />
					</GridItem>
					<GridItem
						colSpan={4}
						colStart={2 - (forceExpand ? 1 : 0)}
					>
						<FormControl>
							<FormLabel>Dateien</FormLabel>
							<Input
								value={
									currentTicket.files?.length
										? `Dateien: ${currentTicket.files.length}`
										: "Dateien hochladen"
								}
								opacity={1}
								_focus={{ opacity: 1, caretColor: "transparent" }}
								cursor="pointer"
								onClick={fileGalleryModal.onOpen}
								leftIcon={
									<Box color={colors.blue}>
										<TbPaperclip />
									</Box>
								}
							/>
						</FormControl>
						{fileGalleryModal.isOpen && (
							<FileGalleryModal
								isOpen={fileGalleryModal.isOpen}
								onClose={fileGalleryModal.onClose}
								onComplete={handleOnCompleteFileUpload}
								path={`baustellen/${constructionSiteId}`}
								createCustomMediaMetadata={createCustomMediaMetadata}
								existingFileNames={currentTicket.files ?? []}
								onDeleteCallback={handleOnFileDelete}
								constructionSiteId={constructionSiteId}
								ticketId={currentTicket.id}
							/>
						)}
					</GridItem>
					<GridItem
						colSpan={4}
						colStart={2 - (forceExpand ? 1 : 0)}
					>
						<FormControl>
							<FormLabel>Dateien verlinken</FormLabel>
							<Input
								value={
									currentTicket.relatedFiles?.length
										? `Verlinkte Dateien: ${currentTicket.relatedFiles.length}`
										: "Dateien verlinken"
								}
								opacity={1}
								_focus={{ opacity: 1, caretColor: "transparent" }}
								cursor="pointer"
								onClick={fileLinkModal.onOpen}
								leftIcon={
									<Box color={colors.blue}>
										<TbPaperclip />
									</Box>
								}
							/>
						</FormControl>
						{fileLinkModal.isOpen && (
							<LinkFilesModal
								isOpen={fileLinkModal.isOpen}
								onClose={fileLinkModal.onClose}
								constructionSiteId={constructionSiteId}
								defaultFiles={currentTicket.relatedFiles}
								onComplete={(selectedFiles) => {
									setValue(`${getRegisterPrefix()}relatedFiles`, selectedFiles);
								}}
							/>
						)}
					</GridItem>
					<GridItem
						colStart={2 - (forceExpand ? 1 : 0)}
						colSpan={4}
					>
						<Divider />
					</GridItem>
				</>
			)}
		</Grid>
	);
}
