import { Tag, TagCloseButton, TagLabel } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface EmailChipProps {
	email: string;
	onDelete?: (index: number) => void;
	index: number;
}

export const EmailChip: FC<EmailChipProps> = ({ email, onDelete, index }) => {
	return (
		<Tag
			size="md"
			variant="subtle"
			colorScheme="gray"
			_active={{ opacity: 0.75 }}
		>
			<TagLabel>{email}</TagLabel>
			{onDelete !== undefined && (
				<TagCloseButton
					onClick={() => {
						onDelete?.(index);
					}}
				/>
			)}
		</Tag>
	);
};
