import { Box, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TimelineItemWrapperProps {
	children?: React.ReactNode;
}

export const TimelineItemWrapper: FC<TimelineItemWrapperProps> = ({ children }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Box
			paddingX={3}
			paddingY={2}
			marginBottom={4}
			rounded="lg"
			borderWidth={1}
			backgroundColor={colors.whiteAdjusted}
			boxShadow="sm"
			height="max-content"
			position="relative"
		>
			{children}
		</Box>
	);
};
