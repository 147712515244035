import { ax } from "@/core";
import { ProjectRequestActivityForm } from "@/forms/project-request-activity-form";
import {
	type ProjectRequestActivityFormSchema,
	projectRequestActivityFormSchema,
} from "@/forms/project-request-activity-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { moment } from "@msuite/katana";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProjectRequestActivityModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
}

export const AddProjectRequestActivityModal: FC<AddProjectRequestActivityModalProps> = ({
	isOpen,
	onClose,
	projectRequestId,
}) => {
	/** Hooks */
	const methods = useForm<ProjectRequestActivityFormSchema>({
		defaultValues: {
			activityType: "call",
			date: moment().format("YYYY-MM-DD"),
			priority: "medium",
			startTime: moment().add(2, "hour").minutes(0).format("HH:mm"),
			endTime: moment().add(4, "hour").minutes(0).format("HH:mm"),
		},
		resolver: zodResolver(projectRequestActivityFormSchema),
	});

	/** Functions */
	async function handleOnSubmit(values: ProjectRequestActivityFormSchema) {
		try {
			await ax.post(`/v2/project-requests/${projectRequestId}/activity`, {
				data: { values },
			});
			handleOnClose();
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnReject(errors: typeof methods.formState.errors) {
		console.error(errors);
		toast.error("Fehlgeschlagen");
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Aktivität hinzufügen</ModalHeader>
						<ModalBody>
							<ProjectRequestActivityForm projectRequestId={projectRequestId} />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={methods.formState.isSubmitting}
								>
									Erstellen
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
