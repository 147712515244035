import {
	type ProjectRequestTimelineActivityEntry,
	findMentionNames,
	getActivityTypeString,
	getCallNotePriorityString,
	moment,
	removeMentions,
} from "@msuite/katana";
import {
	FormControl,
	FormLabel,
	HStack,
	Highlight,
	Label,
	Text,
	useAuthContext,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { ActivityMenu } from "./activity-menu";
import { TimelineActivityPriority } from "./timeline-activity-priority";
// import { NoteMenu } from "./note-menu";

/** Props Interface */
interface TimelineActivityEntryProps {
	entry: ProjectRequestTimelineActivityEntry & { id: string };
	projectRequestId: string;
}

export const TimelineActivityEntry: FC<TimelineActivityEntryProps> = ({
	entry,
	projectRequestId,
}) => {
	/** Context */
	const { colors } = useUIContext();
	const { userId } = useAuthContext();

	/** Computed */
	const mentionNames = findMentionNames(entry.note);

	/** Render */
	return (
		<Fragment>
			{entry.updatedAt && (
				<Label>Bearbeitet am {moment(entry.updatedAt).format("dddd, L [um] HH:mm [Uhr]")}</Label>
			)}
			{userId === entry.createdBy && (
				<ActivityMenu
					projectRequestId={projectRequestId}
					entry={entry}
				/>
			)}
			<Text fontWeight="bold">{entry.title}</Text>
			<HStack spacing={12}>
				<FormControl width="max-content">
					<FormLabel>Typ</FormLabel>
					<Text>{getActivityTypeString(entry.activityType)}</Text>
				</FormControl>
				<FormControl width="max-content">
					<FormLabel>Priorität</FormLabel>
					<HStack
						spacing={2}
						alignItems="center"
					>
						<TimelineActivityPriority activity={entry} />
						<Text>{getCallNotePriorityString(entry.priority)}</Text>
					</HStack>
				</FormControl>
			</HStack>
			<HStack spacing={12}>
				<FormControl width="max-content">
					<FormLabel>Datum</FormLabel>
					<Text>{moment(entry.date).format("dddd, L")}</Text>
				</FormControl>
				<FormControl width="max-content">
					<FormLabel>Start</FormLabel>
					<Text>{entry.startTime} Uhr</Text>
				</FormControl>
				<FormControl width="max-content">
					<FormLabel>Ende</FormLabel>
					<Text>{entry.endTime} Uhr</Text>
				</FormControl>
			</HStack>
			{entry.participants?.length ? (
				<FormControl>
					<Label>Teilnehmer</Label>
					<HStack>
						<Text>
							{entry.participants.map((participant) => participant.displayName).join(", ")}
						</Text>
					</HStack>
				</FormControl>
			) : null}
			{entry.note?.length ? (
				<FormControl>
					<FormLabel>Notiz</FormLabel>
					<Text whiteSpace="pre-line">
						<Highlight
							query={mentionNames}
							styles={{
								color: colors.blue,
								opacity: 0.75,
								":hover": {
									cursor: "pointer",
									opacity: 1,
								},
							}}
						>
							{removeMentions(entry.note)}
						</Highlight>
					</Text>
				</FormControl>
			) : null}
		</Fragment>
	);
};
