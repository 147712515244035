import { ProjectRequestDeadlineDiagram } from "@/components/card";
import { ProjectRequestClient } from "@/components/card/project-request-card/project-request-client";
import { EmployeeAvatar } from "@/components/employee";
import { type ProjectRequest, getCompanyAddressString } from "@msuite/katana";
import {
	Box,
	HStack,
	Heading,
	IconButton,
	Text,
	Tooltip,
	VStack,
	useUIContext,
} from "@msuite/picasso";
import type { FC } from "react";
import { TbMap } from "react-icons/tb";
import { ProjectRequestFinishing } from "./project-request-finishing";

/** Props Interface */
interface ProjectRequestHeaderProps {
	projectRequest: ProjectRequest | undefined;
	projectRequestId: string;
}

export const ProjectRequestHeader: FC<ProjectRequestHeaderProps> = ({
	projectRequest,
	projectRequestId,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Functions */
	function handleOnOpenMaps() {
		window.open(
			`https://www.google.com/maps/search/?api=1&query=${projectRequest?.street ?? ""},+${projectRequest?.houseNumber ?? ""}+${projectRequest?.zipCode ?? ""}+${projectRequest?.city ?? ""}`,
			"_blank",
		);
	}

	/** Render */
	return (
		<VStack
			rounded="xl"
			backgroundColor={colors.whiteAdjusted}
			spacing={2}
			overflow="hidden"
			flexShrink={0}
			borderWidth={1}
			py={4}
			px={5}
			position="relative"
		>
			<HStack justify="space-between">
				<Heading>{projectRequest?.projectName}</Heading>
				<ProjectRequestFinishing
					projectRequestId={projectRequestId}
					projectRequest={projectRequest}
				/>
			</HStack>
			<HStack spacing={2}>
				<Text>
					{getCompanyAddressString({
						street: projectRequest?.street,
						number: projectRequest?.houseNumber,
						zipCode: projectRequest?.zipCode,
						city: projectRequest?.city,
					})}
				</Text>
				<Tooltip label="Auf Google Maps anzeigen">
					<IconButton
						size="xs"
						onClick={handleOnOpenMaps}
						icon={<TbMap />}
						aria-label="Auf Google Maps anzeigen"
					/>
				</Tooltip>
			</HStack>
			<HStack justifyContent="space-between">
				<ProjectRequestClient projectRequest={projectRequest} />
				<HStack mt={1}>
					{[projectRequest?.owner].concat(projectRequest?.coOwners).map((employeeId) => (
						<EmployeeAvatar
							employeeId={employeeId}
							withName
							key={employeeId}
						/>
					))}
				</HStack>
			</HStack>
			<Box
				mt={1}
				mx={-5}
				borderTopWidth={1}
				px={5}
				pt={2}
			>
				<ProjectRequestDeadlineDiagram projectRequest={projectRequest} />
			</Box>
		</VStack>
	);
};
