import { ax, db } from "@/core";
import { type ConstructionSiteDocument, generateId } from "@msuite/katana";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Portal,
	VStack,
	useAuthContext,
	useDisclosure,
} from "@msuite/picasso";
import { DropzoneModal } from "components/upload";
import { doc, setDoc } from "firebase/firestore";
import { type FC, Fragment } from "react";
import { useForm, useWatch } from "react-hook-form";

interface IFile {
	file_name: string;
}

/** Props Interface */
interface HeaderProps {
	projectRequestId: string;
	headerRef: React.RefObject<HTMLDivElement>;
}

export const Header: FC<HeaderProps> = ({ headerRef, projectRequestId }) => {
	/** Context */
	const { user } = useAuthContext();
	const methods = useForm<IFile>({
		defaultValues: { file_name: "" },
	});
	const filePaths = `projectRequests/${projectRequestId}/documents`;

	const fileName = useWatch({ control: methods.control, name: "file_name" });

	/** Hooks */
	const { onOpen, isOpen, onClose } = useDisclosure();

	/** Functions */
	function handleClose() {
		methods.reset();
		onClose();
	}

	async function onComplete(files: File[]) {
		if (!user?.id) return;
		const documentId = generateId();
		const file = files[0];
		const document: ConstructionSiteDocument = {
			file_name: fileName,
			file_path: `${filePaths}/${file.name}`,
			created_by: user?.id,
			created_at: new Date().toISOString(),
		};
		await setDoc(doc(db, filePaths, documentId), document);
		await ax.post(`/v2/project-requests/${projectRequestId}/document/${documentId}`);
		methods.reset();
	}

	/** Render */
	return (
		<Fragment>
			<DropzoneModal
				isOpen={isOpen}
				onClose={handleClose}
				onComplete={onComplete}
				uploadFilePath={filePaths}
				isDisabled={fileName?.length < 4}
				maxFiles={1}
				headDecoration={
					<VStack>
						<FormControl>
							<FormLabel>Name</FormLabel>
							<Input
								placeholder="z.B. Dokument XYZ"
								{...methods.register("file_name")}
							/>
						</FormControl>
					</VStack>
				}
			/>
			<Portal containerRef={headerRef}>
				<Button
					size="sm"
					onClick={onOpen}
				>
					Datei hochladen
				</Button>
			</Portal>
		</Fragment>
	);
};
