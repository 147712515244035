import { db } from "@/core";
import type { ProjectRequestTimelineActivityEntry } from "@msuite/katana";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useActivities({
	projectRequestId,
	date,
}: {
	projectRequestId: string;
	date: string;
}) {
	const [activities, setActivites] = useState<
		(ProjectRequestTimelineActivityEntry & { id: string })[]
	>([]);

	useEffect(() => {
		function subscribe() {
			const collectionRef = collection(db, `projectRequests/${projectRequestId}/requestTimeline`);
			const q = query(collectionRef, where("type", "==", "activity"), where("date", "==", date));
			return onSnapshot(q, (snapshot) => {
				const data = snapshot.docs.map(
					(doc) =>
						({
							...doc.data(),
							id: doc.id,
						}) as ProjectRequestTimelineActivityEntry & { id: string },
				);
				data.sort((a, b) => (a.startTime ?? "00:00").localeCompare(b.startTime ?? "00:00"));
				setActivites(data);
			});
		}

		const unsubscribe = subscribe();
		return () => {
			unsubscribe();
		};
	}, [projectRequestId, date]);

	return {
		activities,
	};
}
