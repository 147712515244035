import { ax } from "@/core";
import type { ISiteManagersApiResponse } from "@msuite/katana";
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	IconButton,
	Select,
	VStack,
	useApi,
} from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IoTrash } from "react-icons/io5";
import { TbPlus } from "react-icons/tb";
import type { ProjectRequestFormType } from "./schema";

export const CoOwnersPicker: FC = () => {
	/** Context */
	const methods = useFormContext<ProjectRequestFormType>();
	const fields = useWatch({ control: methods.control, name: "coOwners" });

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});

	/** Functions */
	function handleOnAddCoOwner() {
		const currentFields = methods.getValues("coOwners");
		methods.setValue("coOwners", [...(currentFields ?? []), ""]);
	}

	function handleOnRemoveCoOwner(index: number) {
		const currentFields = methods.getValues("coOwners");
		const newFields = [...(currentFields ?? [])].filter((_, i) => i !== index);
		methods.setValue("coOwners", newFields);
	}

	/** Render */
	return (
		<FormControl>
			<HStack
				justifyContent="space-between"
				mb={2}
			>
				<FormLabel>Co-Besitzer</FormLabel>
				<IconButton
					size="xs"
					icon={<TbPlus />}
					aria-label="add"
					onClick={handleOnAddCoOwner}
				/>
			</HStack>
			<VStack spacing={2}>
				{!fields?.length && (
					<Select
						placeholder="Keine Co-Besitzer ausgewählt"
						isDisabled
					/>
				)}
				{fields?.map((_, i) => (
					<Grid
						key={_ ?? Math.random()}
						templateColumns="1fr max-content"
						gap={4}
					>
						<Select
							{...methods.register(`coOwners.${i}`)}
							placeholder="Bitte auswählen"
						>
							{data?.employees?.map((employee) => (
								<option
									key={employee.id}
									value={employee.id}
								>
									{employee.vorname} {employee.nachname}
								</option>
							))}
						</Select>
						<IconButton
							variant="ghost"
							aria-label="remove"
							onClick={() => handleOnRemoveCoOwner(i)}
							icon={
								<Box color="red.500">
									<IoTrash />
								</Box>
							}
						/>
					</Grid>
				))}
			</VStack>
		</FormControl>
	);
};
