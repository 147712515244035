import { Avatar, HStack, Text } from "@msuite/picasso";
import type { FC } from "react";
import type { SuggestionDataItem } from "react-mentions";

export const MentionSuggestion: FC<SuggestionDataItem> = ({ display }) => {
	/** Render */
	return (
		<HStack
			spacing={4}
			px={2}
			py={2}
			_focus={{
				opacity: 0,
			}}
		>
			<Avatar
				size="xs"
				name={display}
			/>
			<Text>{display}</Text>
		</HStack>
	);
};
