import { ax, db } from "@/core";
import { ProjectRequestNoteForm } from "@/forms/project-request-note-form";
import type { ProjectRequestNoteFormType } from "@/forms/project-request-note-form/schema";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import { doc, getDoc } from "firebase/firestore";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProjectRequestNoteModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
	noteId: string;
}

export const EditProjectRequestNoteModal: FC<AddProjectRequestNoteModalProps> = ({
	isOpen,
	onClose,
	projectRequestId,
	noteId,
}) => {
	/** Hooks */
	const methods = useForm<ProjectRequestNoteFormType>({
		defaultValues: async () => {
			const noteRef = doc(db, `projectRequests/${projectRequestId}/requestTimeline/${noteId}`);
			const note = (await getDoc(noteRef)).data() as ProjectRequestNoteFormType;
			return note;
		},
	});

	/** Functions */
	async function handleOnSubmit(values: ProjectRequestNoteFormType) {
		await ax.patch(`/v2/project-requests/${projectRequestId}/note/${noteId}`, {
			data: { values },
		});
		handleOnClose();
	}

	async function handleOnReject(errors: typeof methods.formState.errors) {
		console.error(errors);
		toast.error("Fehlgeschlagen");
	}

	function handleOnClose() {
		methods.reset();
		onClose();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Notiz bearbeiten</ModalHeader>
						<ModalBody>
							<ProjectRequestNoteForm key={JSON.stringify(methods.formState.defaultValues)} />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									onClick={handleOnClose}
									variant="ghost"
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									isLoading={methods.formState.isSubmitting}
								>
									Absenden
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
