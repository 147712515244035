import { Divider, FormControl, FormLabel, Grid, Input, Select, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { CoOwnersPicker } from "./co-owners-picker";
import { OwnerPicker } from "./owner-picker";
import { ProjectRequestFormClient } from "./project-request-form-client";
import { FormError, type ProjectRequestFormType } from "./schema";

export const ProjectRequestForm: FC = () => {
	/** Hooks */
	const methods = useFormContext<ProjectRequestFormType>();

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Eingangsdatum</FormLabel>
				<Input
					type="date"
					{...methods.register("receiptDate")}
				/>
				<FormError name="receiptDate" />
			</FormControl>
			<FormControl>
				<FormLabel>Abgabedatum</FormLabel>
				<Input
					type="date"
					{...methods.register("submissionDate")}
				/>
				<FormError name="submissionDate" />
			</FormControl>
			<FormControl>
				<FormLabel>Medium</FormLabel>
				<Select {...methods.register("medium")}>
					<option value="email">E-Mail</option>
					<option value="post">Post</option>
					<option value="digital-platform">Digital Platform</option>
				</Select>
				<FormError name="medium" />
			</FormControl>
			<Divider />
			<ProjectRequestFormClient />
			<FormControl>
				<FormLabel>Art</FormLabel>
				<Select {...methods.register("clientType")}>
					<option
						key="public"
						value="public"
					>
						Öffentlich
					</option>
					<option
						key="private-consumer"
						value="private-consumer"
					>
						Privat (Verbraucher)
					</option>
					<option
						key="private-business"
						value="private-business"
					>
						Privat (Gewerblich)
					</option>
				</Select>
			</FormControl>
			<FormControl>
				<FormLabel>Projektbezeichnung</FormLabel>
				<Input
					{...methods.register("projectName")}
					placeholder="z.B. Schillerstraße 3"
				/>
				<FormError name="projectName" />
			</FormControl>
			<Grid
				templateColumns="2fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>Straße</FormLabel>
					<Input
						{...methods.register("street")}
						placeholder="z.B. Schillerstraße"
					/>
					<FormError name="street" />
				</FormControl>
				<FormControl>
					<FormLabel>Nr.</FormLabel>
					<Input
						{...methods.register("houseNumber")}
						placeholder="z.B. 3"
					/>
					<FormError name="houseNumber" />
				</FormControl>
			</Grid>
			<Grid
				templateColumns="1fr 1fr"
				gap={6}
			>
				<FormControl>
					<FormLabel>PLZ</FormLabel>
					<Input
						{...methods.register("zipCode")}
						placeholder="z.B. 13158"
					/>
					<FormError name="zipCode" />
				</FormControl>
				<FormControl>
					<FormLabel>Ort</FormLabel>
					<Input
						{...methods.register("city")}
						placeholder="z.B. Berlin"
					/>
					<FormError name="city" />
				</FormControl>
			</Grid>
			<OwnerPicker />
			<CoOwnersPicker />
		</VStack>
	);
};
