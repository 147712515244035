import { FileCardUploaded } from "@/components/card";
import { db } from "@/core";
import type { ConstructionSite } from "@msuite/katana";
import {
	Button,
	Grid,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SegmentedControl,
	TicketCardBase,
	VStack,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { type FC, useMemo, useState } from "react";

/** Props Interface */
interface LinkFilesModalProps {
	isOpen: boolean;
	onClose: () => void;
	constructionSiteId?: string;
	defaultFiles?: string[];
	onComplete?: (fileNames: string[]) => void;
}

export const LinkFilesModal: FC<LinkFilesModalProps> = ({
	onClose,
	isOpen,
	constructionSiteId,
	defaultFiles = [],
	onComplete,
}) => {
	/** State */
	const [selectedIds, setSelectedIds] = useState<string[]>(defaultFiles);
	const [currentTicketState, setCurrentTicketState] = useState<"active" | "inactive">("active");

	/** Hooks */
	const { colors } = useUIContext();
	const { data: constructionSite } = useDocument<ConstructionSite>(db, {
		path: `baustellen/${constructionSiteId}`,
		subscribe: true,
		queryClientOptions: {
			enabled: !!constructionSiteId,
			disabled: !constructionSiteId,
		},
	});
	const activeTickets = constructionSite?.tickets ?? [];
	const inactiveTickets = constructionSite?.inactive ?? [];

	/** Functions */
	function handleSubmit() {
		onComplete?.(selectedIds);
		onClose();
	}

	function handleOnSelect(fileName: string) {
		if (selectedIds.includes(fileName)) {
			setSelectedIds(selectedIds.filter((id) => id !== fileName));
		} else {
			setSelectedIds([...selectedIds, fileName]);
		}
	}

	const tickets = useMemo(() => {
		const tickets = currentTicketState === "active" ? activeTickets : inactiveTickets;
		return tickets.filter((ticket) => {
			const filesLength = ticket.files?.length ?? 0;
			if (filesLength > 0) return true;
			return false;
		});
	}, [currentTicketState, activeTickets, inactiveTickets]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="3xl"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Medien auswählen</ModalHeader>
				<ModalBody>
					<VStack>
						<SegmentedControl
							options={[
								{ label: "Aktiv", value: "active" },
								{ label: "Inaktiv", value: "inactive" },
							]}
							value={currentTicketState}
							onChange={setCurrentTicketState}
						/>
						<VStack spacing={4}>
							{tickets.map((ticket) => (
								<VStack
									key={ticket.id}
									spacing={2}
								>
									<TicketCardBase
										backgroundColor={colors.whiteAdjusted}
										ticket={ticket}
									/>
									<Grid
										templateColumns="repeat(6,1fr)"
										gap={4}
									>
										{ticket.files?.map((fileName, i) => (
											<FileCardUploaded
												fileName={fileName}
												path={`baustellen/${constructionSiteId}`}
												key={fileName + i * 2}
												withoutDelete
												onSelect={() => {
													handleOnSelect(fileName);
												}}
												isSelected={selectedIds.includes(fileName)}
											/>
										))}
									</Grid>
								</VStack>
							))}
						</VStack>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							variant="ghost"
							onClick={onClose}
						>
							abbrechen
						</Button>
						<Button onClick={handleSubmit}>Fertig</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
