import { EditProjectRequestModal } from "@/modals/sales/edit-project-request-modal";
import { Button, useDisclosure } from "@msuite/picasso";
import { type FC, Fragment } from "react";

/** Props Interface */
interface ActionsProps {
	projectRequestId: string;
}

export const Actions: FC<ActionsProps> = ({ projectRequestId }) => {
	/** Hooks */
	const editProjectRequestModal = useDisclosure();

	/** Render */
	return (
		<Fragment>
			<Button onClick={editProjectRequestModal.onOpen}>Bearbeiten</Button>
			{editProjectRequestModal.isOpen && (
				<EditProjectRequestModal
					projectRequestId={projectRequestId}
					isOpen={editProjectRequestModal.isOpen}
					onClose={editProjectRequestModal.onClose}
				/>
			)}
		</Fragment>
	);
};
