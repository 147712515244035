import type { ProjectRequestTimelineReopenEntry } from "@msuite/katana";
import { Text } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TimelineReopenEntryProps {
	entry: ProjectRequestTimelineReopenEntry & { id: string };
	projectRequestId: string;
}

export const TimelineReopenEntry: FC<TimelineReopenEntryProps> = () => {
	return <Text>Das Projekt wurde wieder eröffnet.</Text>;
};
