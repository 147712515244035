import type {
	ProjectRequestTimelineEntry,
	ProjectRequestTimelineFinalResultEntry,
} from "@msuite/katana";
import { Box, Center, Text, useUIContext } from "@msuite/picasso";
import { type FC, useMemo } from "react";
import { IoCall } from "react-icons/io5";
import {
	TbArrowBackUp,
	TbArrowRightDashed,
	TbAsterisk,
	TbCheck,
	TbFile,
	TbFileOff,
	TbNote,
	TbX,
} from "react-icons/tb";

/** Props Interface */
interface TimelineIconLineProps {
	entry: ProjectRequestTimelineEntry;
}

export const TimelineIconLine: FC<TimelineIconLineProps> = ({ entry }) => {
	/** Context */
	const { colors } = useUIContext();

	const iconColor = useMemo(() => {
		if (entry.type === "creation") return colors.orange;
		if (entry.type === "state-change") return colors.emerald;
		if (entry.type === "note") return colors.yellow;
		if (entry.type === "activity") return colors.blue;
		if (entry.type === "reopen") return colors.black;
		if (entry.type === "final-result") {
			if ((entry as ProjectRequestTimelineFinalResultEntry).result === "win") return colors.emerald;
			return colors.red;
		}
		if (entry.type === "file-upload") return colors.orange;
		if (entry.type === "file-delete") return colors.red;
		return colors.gray;
	}, [colors, entry.type]);

	/** Render */
	return (
		<Box
			width="100%"
			position="relative"
			pb="20px"
		>
			<Center
				backgroundColor={colors.whiteAdjusted}
				width="50%"
				rounded="full"
				borderWidth={1}
				position="sticky"
				top="2.5rem"
				zIndex={2}
				style={{
					aspectRatio: "1/1",
				}}
			>
				<Text color={iconColor}>
					{entry.type === "reopen" && <TbArrowBackUp />}
					{entry.type === "state-change" && <TbArrowRightDashed />}
					{entry.type === "creation" && <TbAsterisk />}
					{entry.type === "note" && <TbNote />}
					{entry.type === "activity" && <IoCall />}
					{entry.type === "file-upload" && <TbFile />}
					{entry.type === "file-delete" && <TbFileOff />}
					{entry.type === "final-result" &&
						((entry as ProjectRequestTimelineFinalResultEntry).result === "win" ? (
							<TbCheck />
						) : (
							<TbX />
						))}
				</Text>
			</Center>
			<Box
				id="timeline-icon-line-background"
				position="absolute"
				left="25%"
				width="0"
				top="0"
				borderLeftWidth={1}
				height="100%"
				zIndex={1}
			/>
		</Box>
	);
};
