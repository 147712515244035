import { useSalesStore } from "@/sites/sales/sales/store";
import type { ProjectRequestTimelineFinalResultEntry } from "@msuite/katana";
import { FormControl, Label, Text, VStack } from "@msuite/picasso";
import { type FC, Fragment } from "react";

/** Props Interface */
interface TimelineFinalResultEntryProps {
	entry: ProjectRequestTimelineFinalResultEntry;
	projectRequestId: string;
}

export const TimelineFinalResultEntry: FC<TimelineFinalResultEntryProps> = ({ entry }) => {
	/** Store */
	const salesStore = useSalesStore();

	const selectedReason =
		salesStore.salesConfiguration?.projectLoseReasons
			.concat(salesStore.salesConfiguration.archivedProjectLoseReasons)
			.find((reason) => reason.id === entry.loseReason)?.name ?? "Sonstiges";

	/** Render */
	return (
		<Fragment>
			<Text>
				Projektanfrage wurde als <strong>{entry.result === "win" ? "gewonnen" : "verloren"}</strong>{" "}
				markiert.
			</Text>
			{entry.result === "lose" ? (
				<VStack spacing={4}>
					{entry.loseReason ? (
						<FormControl>
							<Label>Grund</Label>
							<Text>{selectedReason}</Text>
						</FormControl>
					) : null}
					{entry.loseReason === "misc" ? (
						<FormControl>
							<Label>Erläuterung (Sonstiges)</Label>
							<Text>{entry.loseReasonMisc}</Text>
						</FormControl>
					) : null}
					{entry.loseDescription ? (
						<FormControl>
							<Label>Beschreibung</Label>
							<Text>{entry.loseDescription}</Text>
						</FormControl>
					) : null}
				</VStack>
			) : null}
		</Fragment>
	);
};
