import { EditProjectRequestActivityModal } from "@/modals/sales/edit-project-request-activity-modal";
import type { ProjectRequestTimelineActivityEntry } from "@msuite/katana";
import { Box, HStack, Text, useDisclosure } from "@msuite/picasso";
import { motion } from "framer-motion";
import { type FC, Fragment } from "react";
import { IoPeople } from "react-icons/io5";

/** Props Interface */
interface ActivityItemProps {
	projectRequestId: string;
	activity: ProjectRequestTimelineActivityEntry & { id: string };
	index: number;
	constraintsRef: React.RefObject<HTMLDivElement>;
}

export const ActivityItem: FC<ActivityItemProps> = ({ activity, projectRequestId }) => {
	/** Hooks */
	const editProjectRequestActivityModal = useDisclosure();

	/** Computed */
	const { startPercentage, endPercentage } = getHeightSpan(activity);

	/** Render */
	return (
		<Fragment>
			<motion.div
				className="activity-item"
				style={{
					position: "absolute",
					width: "83.33333%",
					right: 0,
					padding: "0rem 0.5rem",
				}}
				initial={{
					top: `${startPercentage}%`,
					bottom: `${100 - endPercentage}%`,
				}}
				animate={{
					top: `${startPercentage}%`,
					bottom: `${100 - endPercentage}%`,
				}}
				whileHover={{
					zIndex: 3,
				}}
			>
				<Box
					onClick={editProjectRequestActivityModal.onOpen}
					width="100%"
					height="100%"
					overflow="hidden"
					rounded="md"
					padding={2}
					bgGradient="linear(to-br, emerald.700, emerald.400)"
					cursor="pointer"
					_hover={{
						opacity: 0.95,
					}}
					_active={{
						opacity: 0.8,
					}}
				>
					<Text
						fontSize="sm"
						fontWeight="medium"
						color="white"
					>
						{activity.title}
					</Text>
					<Text
						fontSize="xs"
						fontWeight="medium"
						color="white"
					>
						{activity.startTime} - {activity.endTime} Uhr
					</Text>
					{activity.participants?.length ? (
						<HStack
							color="white"
							fontSize="xs"
							spacing={1}
						>
							<IoPeople />
							<Text>{activity.participants.length}</Text>
						</HStack>
					) : null}
				</Box>
			</motion.div>
			{editProjectRequestActivityModal.isOpen && (
				<EditProjectRequestActivityModal
					isOpen={editProjectRequestActivityModal.isOpen}
					onClose={editProjectRequestActivityModal.onClose}
					projectRequestId={projectRequestId}
					activityId={activity.id}
				/>
			)}
		</Fragment>
	);
};

function calculateMinutes(time: string | undefined) {
	const [hours, minutes] = (time ?? "00:00").split(":");
	return Number(hours) * 60 + Number(minutes);
}

const TOTAL_MINUTES = 24 * 60;

function getHeightSpan(activity: ProjectRequestTimelineActivityEntry) {
	const startMinutes = calculateMinutes(activity.startTime);
	const endMinutes = calculateMinutes(activity.endTime);
	const startPercentage = (startMinutes / TOTAL_MINUTES) * 100;
	const endPercentage = (endMinutes / TOTAL_MINUTES) * 100;

	return {
		startPercentage,
		endPercentage,
	};
}
