import type { ProjectRequestTimelineCreationEntry } from "@msuite/katana";
import { Text } from "@msuite/picasso";
import type { FC } from "react";

/** Props Interface */
interface TimelineCreationEntryProps {
	entry: ProjectRequestTimelineCreationEntry;
}

export const TimelineCreationEntry: FC<TimelineCreationEntryProps> = () => {
	/** Render */
	return <Text>Projektanfrage wurde erstellt.</Text>;
};
