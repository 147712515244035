import { storage } from "@/core";
import type { CustomMediaMetadata } from "@msuite/katana";
import {
	type StorageReference,
	type UploadTaskSnapshot,
	ref,
	uploadBytesResumable,
} from "firebase/storage";
import { useState } from "react";
import { handleHeicConversion } from "util/file";

export const useUpload = (
	uploadFilePath: string,
	onClose: () => void,
	onComplete?: (files: File[]) => unknown,
	customMetadata?: CustomMediaMetadata,
	defaultFiles?: File[],
) => {
	/** State */
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>(defaultFiles ?? []);
	const [progress, setProgress] = useState<{
		value: number;
		max: number;
		progress: number;
		file_nr: number;
	}>({
		value: 0,
		max: 0,
		progress: 0,
		file_nr: 0,
	});

	/** Handlers */
	const performUpload = async () => {
		try {
			setIsUploading(true);
			for (let i = 0; i < files.length; i++) {
				let fileparsed = files[i];

				if (fileparsed.type.includes("heic") || fileparsed.type.includes("heif")) {
					fileparsed = (await handleHeicConversion([fileparsed]))?.[0];
				}

				const storageRef = ref(storage, `${uploadFilePath}/${fileparsed.name}`);
				await uploadFile(storageRef, fileparsed, i);
			}
			onComplete?.(files);
			onClose();
			setFiles([]);
			setProgress({
				value: 0,
				max: 0,
				progress: 0,
				file_nr: 0,
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsUploading(false);
		}
	};

	const uploadFile = async (storageRef: StorageReference, file: File, file_nr: number) => {
		const metadata = { customMetadata: { ...customMetadata } };
		const task = uploadBytesResumable(storageRef, file, metadata);
		task.on("state_changed", (snapshot: UploadTaskSnapshot) => {
			const progress = {
				value: snapshot.bytesTransferred,
				max: snapshot.totalBytes,
				progress: Number.parseInt(
					((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0),
				),
				file_nr,
			};
			setProgress(progress);
		});

		task
			.then(() => {
				const progress = {
					value: 0,
					max: 0,
					progress: 0,
					file_nr: file_nr + 1 > files.length ? files.length : file_nr + 1,
				};
				setProgress(progress);
			})
			.catch((error) => {
				console.error(error);
			});
		return task;
	};

	/** Render */
	return { files, setFiles, performUpload, progress, isUploading };
};
