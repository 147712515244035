import { type Moment, moment } from "@msuite/katana";
import {
	Divider,
	Input,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	useDisclosure,
	useHolidays,
} from "@msuite/picasso";
import { type FC, useEffect, useId, useState } from "react";
import { createPortal } from "react-dom";
import { CalendarBody } from "./calendar-body";
import { CalendarHeader } from "./calendar-header";
import { DatePickerContext } from "./context";
import {
	type BaseItemMode,
	type KeyboardArrow,
	createSafeDefaultDate,
	getDateFormatFromMode,
	getFocusTargetId,
	getReadableStringFromDate,
	inferDateMode,
} from "./handlers";
import { HeaderControls } from "./header-controls";

/** Props Interface */
interface DatePickerProps {
	children?: React.ReactNode;
	disclosure?: ReturnType<typeof useDisclosure>;
	onChange: (data: OnChangeDate) => void;
	defaultValue?: string;
	minValue?: string; // MUST BE IN YYYY-MM-DD FORMAT
}

export type DateMode = BaseItemMode;

export interface OnChangeDate {
	date: Moment;
	mode: DateMode;
}

export const DatePicker: FC<DatePickerProps> = ({
	children,
	disclosure,
	onChange,
	defaultValue: __unsafe__defaultValue,
	minValue = "1970-01-01",
}) => {
	// ! THIS FUNCTION IS ONLY USED TO HANDLE LEGACY WEEK DATES
	const defaultValue = createSafeDefaultDate(__unsafe__defaultValue);
	const inferredDateMode = inferDateMode(defaultValue || moment().format("YYYY-MM-DD"));
	const defaultDateFormat = getDateFormatFromMode(inferredDateMode);

	/** State */
	const [currentValue, setCurrentValue] = useState<string | null>(defaultValue ?? null);
	const [currentMonth, setCurrentMonth] = useState<Moment>(
		(defaultValue ? moment(defaultValue, defaultDateFormat) : moment()).clone().startOf("month"),
	);

	const currentValueString = getReadableStringFromDate(currentValue);

	/** Hooks */
	const { holidaysAsArray: holidays } = useHolidays(currentMonth.year());
	const renderId = useId();
	const _disclosure = useDisclosure();
	const { isOpen, onClose, onOpen } = disclosure ?? _disclosure;

	/** Functions */
	function handleOnClose() {
		onClose();
	}

	function handleOnSelect(date: Moment, mode: BaseItemMode) {
		onChange({ date, mode });
		const formatFromMode = getDateFormatFromMode(mode);
		const newCurrentValue = date.format(formatFromMode);
		setCurrentValue(newCurrentValue);
		onClose();
	}

	function handleNavigation(event: React.KeyboardEvent<HTMLDivElement>) {
		const arrowKey = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key);
		if (!currentValue) return;
		if (!arrowKey) return;
		const inferMode = inferDateMode(currentValue);
		const newTargetId = getFocusTargetId(currentValue, inferMode, event.key as KeyboardArrow);
		if (!newTargetId) return;
		const newTargetMoment = moment(newTargetId, getDateFormatFromMode(inferMode)).clone();
		const newTargetMode = inferDateMode(newTargetId);
		const newTargetMomentType = newTargetMode === "day" ? "date" : newTargetMode;
		if (newTargetMoment.isBefore(minValue, newTargetMomentType)) return;
		setCurrentValue(newTargetId);
	}

	function handleUpdateMonth() {
		if (!currentValue) return;
		const inferMode = inferDateMode(currentValue);
		const newMonth = moment(currentValue, getDateFormatFromMode(inferMode))
			.clone()
			.startOf("month");
		setCurrentMonth(newMonth);
	}

	/** Effects */
	useEffect(() => {
		setCurrentValue(defaultValue ?? null);
	}, [defaultValue]);

	useEffect(() => {
		if (!currentValue) return;
		handleUpdateMonth();
	}, [currentValue]);

	/** Render */
	return (
		<DatePickerContext.Provider
			value={{
				currentValue,
				setCurrentValue,
				currentMonth,
				setCurrentMonth,
				renderId,
				handleOnClose,
				handleOnSelect,
				holidays,
				minValue,
			}}
		>
			<Popover
				placement="bottom-start"
				isOpen={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				closeOnBlur
				isLazy
			>
				<PopoverTrigger>
					{children ?? (
						<div>
							<Input
								cursor="pointer"
								readOnly
								value={currentValueString}
							/>
						</div>
					)}
				</PopoverTrigger>
				{isOpen &&
					createPortal(
						<PopoverContent
							minWidth="max-content"
							onKeyDown={handleNavigation}
						>
							<PopoverHeader>
								<HeaderControls />
							</PopoverHeader>
							<PopoverBody onKeyDown={handleNavigation}>
								<CalendarHeader />
								<div style={{ height: "0.5rem" }} />
								<Divider />
								<div style={{ height: "0.5rem" }} />
								<CalendarBody />
							</PopoverBody>
						</PopoverContent>,
						document.body,
					)}
			</Popover>
		</DatePickerContext.Provider>
	);
};
