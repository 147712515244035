import { DropzoneModalContext } from "@/context/dropzone-modal";
import { Button, ModalButtonGroup, ModalFooter } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { IoCloudUpload } from "react-icons/io5";

/** Props Interface */
interface FooterProps {
	onClose: () => void;
}

export const Footer: FC<FooterProps> = ({ onClose }) => {
	/** Context */
	const { performUpload, isUploading, isDisabled, files } = useContext(DropzoneModalContext);

	/** Render */
	return (
		<ModalFooter>
			<ModalButtonGroup>
				<Button
					variant="ghost"
					onClick={onClose}
					disabled={isUploading}
				>
					abbrechen
				</Button>
				<Button
					leftIcon={<IoCloudUpload />}
					colorScheme="brand"
					isLoading={isUploading}
					onClick={performUpload}
					isDisabled={isDisabled || files?.length === 0}
				>
					Hochladen
				</Button>
			</ModalButtonGroup>
		</ModalFooter>
	);
};
