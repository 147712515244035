import { useSalesStore } from "@/sites/sales/sales/store";
import type { ProjectRequestTimelineStateChangeEntry } from "@msuite/katana";
import { HStack, Tag } from "@msuite/picasso";
import type { FC } from "react";
import { IoArrowForward } from "react-icons/io5";

/** Props Interface */
interface TimelineStateChangeEntryProps {
	entry: ProjectRequestTimelineStateChangeEntry;
}

export const TimelineStateChangeEntry: FC<TimelineStateChangeEntryProps> = ({ entry }) => {
	const salesStore = useSalesStore();
	const columns = salesStore.salesConfiguration?.columns.concat(
		salesStore.salesConfiguration.archivedColumns,
	);
	const fromState = columns?.find((column) => entry.from === column.id);
	const toState = columns?.find((column) => entry.to === column.id);

	/** Render */
	return (
		<HStack spacing={2}>
			<Tag colorScheme="red">{fromState?.name}</Tag>
			<IoArrowForward />
			<Tag colorScheme="emerald">{toState?.name}</Tag>
		</HStack>
	);
};
