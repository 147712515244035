import { Box, Grid, GridItem, useUIContext } from "@msuite/picasso";
import type { FC } from "react";

export const Dashboard: FC = () => {
	const { colors } = useUIContext();

	/** Render */
	return (
		<Grid
			templateColumns="repeat(4, 1fr)"
			gridAutoRows="10rem"
			gridAutoFlow="dense"
			gap={6}
		>
			{Array.from({ length: 8 }).map((_, i) => (
				<GridItem
					colSpan={i % 2 ? 2 : 1}
					width="100%"
					height="100%"
					key={Math.random()}
				>
					<Box
						width="100%"
						height="100%"
						rounded="xl"
						borderWidth={0.5}
						backgroundColor={colors.whiteAdjusted}
					/>
				</GridItem>
			))}
		</Grid>
	);
};
