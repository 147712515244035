import { db } from "@/core";
import type { BaustelleYearlyStatement } from "@msuite/katana";
import { moment } from "@msuite/katana";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useStatements = (baustelleId: string | undefined) => {
	/** State */
	const [responses, setResponses] = useState<BaustelleYearlyStatement[]>([]);

	/** Effects */
	useEffect(() => {
		if (!baustelleId) return;
		const collection_ref = collection(db, "baustellen", baustelleId || "", "yearly_statement");
		const q = query(
			collection_ref,
			where("year", "==", moment().year()),
			orderBy("created_at", "desc"),
		);
		const unsubscribe = onSnapshot(q, (snapshot) => {
			if (snapshot.empty) return setResponses([]);
			const data = snapshot.docs.map(
				(doc) => ({ ...doc.data(), id: doc.id }) as BaustelleYearlyStatement,
			);
			setResponses(data);
		});

		return unsubscribe;
	}, [baustelleId]);

	/** Return */
	return { responses };
};
