import type { ZodSatisfy } from "@/util/zod";
import type { ProjectRequest } from "@msuite/katana";
import { FormErrorMessage } from "@msuite/picasso";
import { ZodIssueCode, z } from "zod";

export const projectRequestLoseReasonFormSchema = z
	.object({
		loseReason: z.string().min(1, "Bitte Grund angeben"),
		loseReasonMisc: z.string().optional(),
		loseDescription: z
			.string()
			.optional()
			.default(() => ""),
	} satisfies ZodSatisfy<Pick<ProjectRequest, "loseReasonMisc" | "loseReason" | "loseDescription">>)
	.superRefine((value, ctx) => {
		if (value.loseReason === "misc") {
			if (!value.loseReasonMisc) {
				ctx.addIssue({
					path: ["loseReasonMisc"],
					code: ZodIssueCode.custom,
					message: "Bitte eine Erläuterung für 'Sonstiges' angeben",
				});
			}
		}
		return value;
	})
	.transform((value) => {
		if (value.loseReason !== "misc") {
			delete value.loseReasonMisc;
		}
		return value;
	});

export type ProjectRequestLoseReasonFormSchema = z.infer<typeof projectRequestLoseReasonFormSchema>;

export const FormError = FormErrorMessage<ProjectRequestLoseReasonFormSchema>;
