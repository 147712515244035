import { AddProjectRequestActivityModal } from "@/modals/sales/add-project-request-activity-modal";
import { AddProjectRequestNoteModal } from "@/modals/sales/add-project-request-note-modal";
import {
	Button,
	HStack,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";
import { TbCalendar, TbFile, TbFileAlert, TbNote, TbPlus } from "react-icons/tb";

/** Props Interface */
interface TimelineActionsProps {
	projectRequestId: string;
}

export const TimelineActions: FC<TimelineActionsProps> = ({ projectRequestId }) => {
	/** Hooks */
	const { colors } = useUIContext();
	const addProjectRequestNoteModal = useDisclosure();
	const addProjectRequestActivityModal = useDisclosure();

	/** Render */
	return (
		<Fragment>
			<Menu placement="bottom-end">
				<MenuButton>
					<Button leftIcon={<TbPlus />}>Hinzufügen</Button>
				</MenuButton>
				<MenuList>
					<MenuItem
						closeOnSelect
						onClick={addProjectRequestActivityModal.onOpen}
					>
						<HStack spacing={2}>
							<Text color={colors.gray}>
								<TbCalendar />
							</Text>
							<Text>Aktivität</Text>
						</HStack>
					</MenuItem>
					<MenuItem
						closeOnSelect
						onClick={addProjectRequestNoteModal.onOpen}
					>
						<HStack spacing={2}>
							<Text color={colors.gray}>
								<TbNote />
							</Text>
							<Text>Notiz</Text>
						</HStack>
					</MenuItem>
					<MenuDivider />
					<MenuItem
						closeOnSelect
						onClick={addProjectRequestNoteModal.onOpen}
					>
						<HStack spacing={2}>
							<Text color={colors.gray}>
								<TbFileAlert />
							</Text>
							<Text>Datei</Text>
						</HStack>
					</MenuItem>
					<MenuItem
						closeOnSelect
						onClick={addProjectRequestNoteModal.onOpen}
					>
						<HStack spacing={2}>
							<Text color={colors.gray}>
								<TbFile />
							</Text>
							<Text>Dokument</Text>
						</HStack>
					</MenuItem>
				</MenuList>
			</Menu>
			<AddProjectRequestNoteModal
				projectRequestId={projectRequestId}
				isOpen={addProjectRequestNoteModal.isOpen}
				onClose={addProjectRequestNoteModal.onClose}
			/>
			<AddProjectRequestActivityModal
				projectRequestId={projectRequestId}
				isOpen={addProjectRequestActivityModal.isOpen}
				onClose={addProjectRequestActivityModal.onClose}
			/>
		</Fragment>
	);
};
