import { DropzoneModalContext } from "@/context/dropzone-modal";
import type { CustomMediaMetadata } from "@msuite/katana";
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@msuite/picasso";
import { type FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Body } from "./body";
import { Footer } from "./footer";
import { useUpload } from "./use-upload";

/** Props Interface */
interface DropzoneModalProps {
	isOpen: boolean;
	onClose: () => void;
	headDecoration?: React.ReactNode;
	uploadFilePath: string;
	onComplete: (files: File[]) => void;
	customMetadata?: CustomMediaMetadata;
	isDisabled?: boolean;
	children?: React.ReactNode;
	maxFiles?: number;
}

export const DropzoneModal: FC<DropzoneModalProps> = ({
	isOpen,
	onClose,
	headDecoration,
	uploadFilePath,
	onComplete,
	customMetadata,
	isDisabled = false,
	maxFiles = 10,
}) => {
	/** State */
	const { files, setFiles, performUpload, progress, isUploading } = useUpload(
		uploadFilePath,
		handleOnClose,
		onComplete,
		customMetadata,
	);

	/** Callbacks */
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (maxFiles === 1) {
				const file = acceptedFiles.at(0);
				if (file) setFiles([file]);
			} else {
				setFiles([
					...files,
					...acceptedFiles.filter((f: File) => !files.map((fl) => fl.name).includes(f.name)),
				]);
			}
		},
		[files, setFiles],
	);

	const onDelete = useCallback(
		(file: File) => {
			setFiles(files.filter((f: File) => f.name !== file.name));
		},
		[files, setFiles],
	);

	function handleOnClose() {
		setFiles([]);
		onClose();
	}

	/** Hooks */
	const dropzone = useDropzone({ onDrop, maxFiles, multiple: maxFiles > 1 });

	/** Render */
	return (
		<DropzoneModalContext.Provider
			value={{
				...dropzone,
				files,
				onDelete,
				performUpload,
				progress,
				isUploading,
				isDisabled,
				maxFiles,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
				size="5xl"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalHeader>Dateien hochladen</ModalHeader>
					<Body headDecoration={headDecoration} />
					<Footer onClose={onClose} />
				</ModalContent>
			</Modal>
		</DropzoneModalContext.Provider>
	);
};
