import { ax } from "@/core";
import { ProjectRequestLoseReasonForm } from "@/forms/project-request-lose-reason-form";
import {
	type ProjectRequestLoseReasonFormSchema,
	projectRequestLoseReasonFormSchema,
} from "@/forms/project-request-lose-reason-form/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	toast,
} from "@msuite/picasso";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

/** Props Interface */
interface AddProjectRequestLoseReasonModalProps {
	isOpen: boolean;
	onClose: () => void;
	projectRequestId: string;
	onComplete: () => Promise<void> | void;
}

export const AddProjectRequestLoseReasonModal: FC<AddProjectRequestLoseReasonModalProps> = ({
	isOpen,
	onClose,
	onComplete,
	projectRequestId,
}) => {
	/** Hooks */
	const methods = useForm<ProjectRequestLoseReasonFormSchema>({
		resolver: zodResolver(projectRequestLoseReasonFormSchema),
	});

	/** Functions */
	async function handleOnSubmit(values: ProjectRequestLoseReasonFormSchema) {
		try {
			await ax.patch(`/v2/project-requests/${projectRequestId}/final-result?result=lose`, {
				data: { values },
			});
			handleOnClose();
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnReject(errors: typeof methods.formState.errors) {
		console.log(errors);
		toast.error(Object.entries(errors)?.at(0)?.[1]?.message);
	}

	function handleOnClose() {
		methods.reset();
		onClose();
		onComplete?.();
	}

	/** Render */
	return (
		<FormProvider {...methods}>
			<Modal
				isOpen={isOpen}
				onClose={handleOnClose}
			>
				<ModalOverlay />
				<form onSubmit={methods.handleSubmit(handleOnSubmit, handleOnReject)}>
					<ModalContent>
						<ModalCloseButton />
						<ModalHeader>Als "Verloren" markieren</ModalHeader>
						<ModalBody>
							<ProjectRequestLoseReasonForm />
						</ModalBody>
						<ModalFooter>
							<ModalButtonGroup>
								<Button
									variant="ghost"
									onClick={handleOnClose}
								>
									abbrechen
								</Button>
								<Button
									type="submit"
									colorScheme="red"
									isLoading={methods.formState.isSubmitting}
								>
									Als "Verloren" markieren
								</Button>
							</ModalButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	);
};
