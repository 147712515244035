import { useConstructionSiteCardContext } from "@/context/construction-site-card";
import { TicketCardContext, useTicketsContext } from "@/context/ticket-card";
import { db } from "@/core";
import { FileGalleryModal } from "@/modals/upload/file-gallery-modal";
import {
	type CustomMediaMetadata,
	type Ticket,
	type TicketMediaMetadata,
	type TicketTypes,
	cleanUndefinedValues,
	moment,
} from "@msuite/katana";
import {
	Box,
	ContextMenu,
	Flex,
	Grid,
	GridItem,
	HStack,
	Text,
	TicketTypeIcon,
	Tooltip,
	VStack,
	toast,
	useDisclosure,
	useUIContext,
	useUpdate,
} from "@msuite/picasso";
import { doc, updateDoc } from "firebase/firestore";
import type { UploadResult } from "firebase/storage";
import { type FC, Fragment, type RefObject, useRef } from "react";
import { Hint } from "../construction-site-card/hint";
import { ActionBar } from "./action-bar";
import { MultiSelectIndicator } from "./segments";
import { TicketDate, type TicketDateMode } from "./ticket-date";
import { TicketEstimation } from "./ticket-estimation";
import { TicketFiles, calculateTicketFilesLength } from "./ticket-files";
import { TicketRemover } from "./ticket-remover";
import { TicketTrimble } from "./ticket-trimble";
import { useContextMenu } from "./use-context-menu";
import { useTicketMetaInformation } from "./use-ticket-meta-information";

/** Props Interface */
interface TicketCardProps {
	ticket: Ticket;
	index: number;
	modules?: TicketCardModule[];
	bottomMargin?: number;
	ticketIndex: number;
	shouldFreeze?: boolean;
}

/** Types */
export type TicketCardModule =
	| "ticket-information"
	| "action-bar"
	| "date"
	| "ticket-remover"
	| "estimation";

export const ticketTypeArray: TicketTypes[] = [
	"Auf",
	"Ab",
	"Um",
	"Abholung",
	"Anlieferung",
	"Hinweis",
];
export const TicketCardDefaultModules: TicketCardModule[] = [
	"ticket-information",
	"action-bar",
	"date",
	"estimation",
];

export function createCustomMediaMetadata(
	constructionSiteId: string,
	ticketId: string,
): CustomMediaMetadata {
	const customMediaMetadata: TicketMediaMetadata = {
		timestamp: moment().toISOString(),
		mediaType: "ticket",
		baustelleId: constructionSiteId,
		ticketId: ticketId,
	};
	return customMediaMetadata;
}

export const TicketCard: FC<TicketCardProps> = ({
	ticket,
	modules = TicketCardDefaultModules,
	ticketIndex,
}) => {
	/** Context */
	const {
		ticketCardModules: _ticketCardModules,
		constructionSite,
		withMultiSelect,
		contextMenuType,
		isInView,
	} = useConstructionSiteCardContext();
	const ticketCardModules = _ticketCardModules ?? modules;
	const { colors } = useUIContext();
	const { selectedTicketIndices, setSelectedTicketIndices } = useTicketsContext();

	/** Hooks */
	const ticketRef = useRef() as RefObject<HTMLDivElement>;
	const fileGalleryModal = useDisclosure();
	const { contextMenuOptions } = useContextMenu(
		contextMenuType,
		ticket,
		constructionSite,
		fileGalleryModal,
	);
	const ticketMetaInformation = useTicketMetaInformation(ticket);
	const update = useUpdate();

	/** Memoized */
	const hasActionBar = ticketCardModules.includes("action-bar");
	const hasDate = ticketCardModules.includes("date");
	const hasEstimation = ticketCardModules.includes("estimation");
	const hasTicketRemover = ticketCardModules.includes("ticket-remover");
	const ticketHasEstimation = hasEstimation && (ticket.estimation?.estimatedTime ?? 0) > 0;
	const ticketHasDate =
		hasDate && (ticket.date !== undefined || ticket.date_earliest_from !== undefined);
	const ticketHasFiles = calculateTicketFilesLength(ticket) > 0;
	const ticketHasTrimble = !!ticket.trimbleConnect?.at(0)?.trimbleLink;
	const isSelected = selectedTicketIndices.includes(ticketIndex);

	/** Functions */
	function handleOnCtrlClick(e: React.MouseEvent) {
		if (!withMultiSelect) return;
		const { ctrlKey, altKey, shiftKey, metaKey } = e;
		if (!ticket?.id) return;
		if (ctrlKey || altKey || shiftKey || metaKey) {
			const isRangeSelection = shiftKey;
			if (isSelected) {
				setSelectedTicketIndices(selectedTicketIndices.filter((index) => index !== ticketIndex));
			} else {
				if (isRangeSelection && selectedTicketIndices.length > 0) {
					const lastSelectedTicketIndex = selectedTicketIndices[selectedTicketIndices.length - 1];
					if (lastSelectedTicketIndex === undefined) return;
					const range = [lastSelectedTicketIndex, ticketIndex].sort((a, b) => a - b);
					const rangeIndices = Array.from(
						{ length: range[1] - range[0] + 1 },
						(_, i) => i + range[0],
					);
					const newRange = [...new Set([...selectedTicketIndices, ...rangeIndices])];
					setSelectedTicketIndices(newRange);
				} else {
					setSelectedTicketIndices([...selectedTicketIndices, ticketIndex]);
				}
			}
		}
	}

	async function handleOnCompleteFileUpload(results: (UploadResult | undefined)[]) {
		try {
			const filteredResults = results.filter((result) => result !== undefined);
			const fileNames = filteredResults
				.map((result) => result?.metadata.name)
				.filter((name) => name !== undefined) as string[];
			const newTicket = {
				...ticket,
				files: [...(ticket.files ?? []), ...fileNames],
			};
			const constructionSiteTickets = constructionSite?.tickets ?? [];
			const ticketIndex = constructionSiteTickets.findIndex((ticket) => ticket.id === newTicket.id);
			if (ticketIndex === -1) return;
			if (!constructionSiteTickets[ticketIndex]) return;
			constructionSiteTickets[ticketIndex] = newTicket;
			const constructionSiteRef = doc(db, `baustellen/${constructionSite?.id}`);
			await updateDoc(constructionSiteRef, {
				tickets: constructionSiteTickets.map((ticket) => cleanUndefinedValues(ticket)),
			});
			update();
			ticketRef?.current?.click();
		} catch (error) {
			console.error();
		}
	}

	async function handleOnFileDelete(fileName: string) {
		try {
			const newTicket = {
				...ticket,
				files: ticket.files?.filter((file) => file !== fileName),
			};
			const constructionSiteTickets = constructionSite?.tickets ?? [];
			const ticketIndex = constructionSiteTickets.findIndex((ticket) => ticket.id === newTicket.id);
			if (ticketIndex === -1) return;
			if (!constructionSiteTickets[ticketIndex]) return;
			constructionSiteTickets[ticketIndex] = newTicket;
			const constructionSiteRef = doc(db, `baustellen/${constructionSite?.id}`);
			await updateDoc(constructionSiteRef, {
				tickets: constructionSiteTickets.map((ticket) => cleanUndefinedValues(ticket)),
			});
			ticketRef?.current?.click();
		} catch (error) {
			console.error(error);
		}
	}

	async function handleOnDeleteTicketDate(ticketIds: string[], mode: TicketDateMode, _?: boolean) {
		try {
			if (!constructionSite?.id) throw new Error("Keine Baustelle gefunden");
			for (const ticketId of ticketIds) {
				const currentTicketIndex = constructionSite?.tickets?.findIndex(
					(ticket) => ticket.id === ticketId,
				);
				if (currentTicketIndex === undefined || currentTicketIndex < 0)
					throw new Error("Ticket nicht gefunden");
				if (!constructionSite?.tickets) throw new Error("Keine Tickets gefunden");
				const currentTicket = constructionSite?.tickets?.[currentTicketIndex];
				if (currentTicket === undefined) throw new Error("Ticket nicht gefunden");
				if (currentTicket.date_earliest_from === undefined && mode === "earliest-from") {
					delete currentTicket.date;
				} else {
					if (mode === "date" || mode === "both") {
						if (mode === "both") {
							delete currentTicket.date_earliest_from;
							if (!currentTicket.date_is_deadline) {
								delete currentTicket.date;
								delete currentTicket.date_is_deadline;
							}
						} else {
							delete currentTicket.date;
							delete currentTicket.date_is_deadline;
						}
					}
					if (mode === "earliest-from" || mode === "both") {
						currentTicket.date_earliest_from = undefined;
					}
				}
				constructionSite.tickets[currentTicketIndex] = currentTicket;
			}
			const constructionSiteRef = doc(db, `baustellen/${constructionSite.id}`);
			await updateDoc(constructionSiteRef, {
				tickets: constructionSite.tickets?.map((ticket) => cleanUndefinedValues(ticket)) ?? [],
			});
		} catch (error) {
			toast.error("Es ist ein Fehler aufgetreten");
		}
	}

	/** Render */
	return (
		<TicketCardContext.Provider
			value={{
				ticket,
				modules: ticketCardModules,
				ticketIndex,
				ticketMetaInformation,
				handleOnDeleteTicketDate,
			}}
		>
			<ContextMenu
				options={contextMenuOptions}
				shouldRender={isInView}
			>
				<Grid
					ref={ticketRef}
					px={1}
					py={2}
					userSelect="none"
					backgroundColor={colors.grayLight}
					borderWidth={1}
					rounded="sm"
					borderLeftWidth={12}
					borderColor={ticketMetaInformation.ticketColor}
					columnGap={6}
					templateColumns="3fr max-content"
					onClick={handleOnCtrlClick}
				>
					<Grid
						templateColumns="max-content 1fr"
						gridAutoRows="max-content"
						alignItems="center"
						columnGap={3}
						rowGap={1}
					>
						<GridItem
							rowSpan={3}
							pl={1}
						>
							<HStack spacing={0}>
								<MultiSelectIndicator
									isSelected={isSelected}
									withMultiSelect={withMultiSelect}
								/>
								<TicketTypeIcon type={ticket.type} />
							</HStack>
						</GridItem>
						<GridItem
							colStart={2}
							rowStart={1}
						>
							<HStack spacing={1}>
								<Text
									fontSize="sm"
									fontWeight="medium"
									color="gray.500"
								>
									{ticket.type}
								</Text>
								{ticket?.note && <Hint hint={ticket.note} />}
							</HStack>
						</GridItem>
						<GridItem maxWidth="max-content">
							<Tooltip
								openDelay={500}
								label={ticket.ticket}
							>
								<Text noOfLines={1}>{ticket.ticket}</Text>
							</Tooltip>
						</GridItem>
						{(ticketHasEstimation || ticketHasDate || ticketHasFiles || ticketHasTrimble) && (
							<Fragment>
								<GridItem
									colStart={2}
									rowStart={3}
								>
									<VStack spacing={1}>
										<Flex
											gap={2}
											alignItems="center"
											wrap="wrap"
											rowGap={0}
										>
											{ticketHasFiles && (
												<Box onClick={fileGalleryModal.onOpen}>
													<TicketFiles
														onOpenGallery={fileGalleryModal.onOpen}
														ticket={ticket}
													/>
												</Box>
											)}
											{ticketHasTrimble && <TicketTrimble />}
											{ticketHasEstimation && <TicketEstimation ticket={ticket} />}
										</Flex>
										{ticketHasDate && (
											<TicketDate
												ticket={ticket}
												handleOnDeleteTicketDate={handleOnDeleteTicketDate}
											/>
										)}
									</VStack>
								</GridItem>
							</Fragment>
						)}
					</Grid>
					{hasActionBar && <ActionBar />}
					{hasTicketRemover && <TicketRemover />}
				</Grid>
			</ContextMenu>
			{isInView && fileGalleryModal.isOpen && (
				<FileGalleryModal
					key={ticket.files?.length ?? 0}
					isOpen={fileGalleryModal.isOpen}
					onClose={fileGalleryModal.onClose}
					onComplete={handleOnCompleteFileUpload}
					path={`baustellen/${constructionSite?.id}`}
					createCustomMediaMetadata={createCustomMediaMetadata}
					existingFileNames={ticket.files ?? []}
					existingRelatedFileNames={ticket.relatedFiles ?? []}
					onDeleteCallback={handleOnFileDelete}
					constructionSiteId={constructionSite?.id}
					ticketId={ticket.id}
				/>
			)}
		</TicketCardContext.Provider>
	);
};
