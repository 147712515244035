import { DropzoneModalContext } from "@/context/dropzone-modal";
import { Text, VStack, useUIContext } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { FileCard } from "./file-card";

export const Files: FC = () => {
	/** Context */
	const { colors } = useUIContext();
	const { files } = useContext(DropzoneModalContext);

	/** Render */
	return (
		<VStack alignItems="stretch">
			{(files?.length ?? 0) > 0 && (
				<Text
					fontWeight="medium"
					fontSize="sm"
					color={colors.gray}
				>
					Dateien ({files?.length})
				</Text>
			)}
			{files?.map((file: File, index: number) => (
				<FileCard
					file={file}
					key={file.name + file.size}
					index={index}
				/>
			))}
		</VStack>
	);
};
