import { db } from "@/core";
import { AddCampaignInvitationModal } from "@/modals/misc/add-campaign-invitation-modal";
import type { CampaignAnswer } from "@msuite/katana";
import {
	Button,
	HStack,
	Heading,
	InnerSiteLayout,
	Input,
	Label,
	SegmentedControl,
	StandardDataTable,
	VStack,
	useCollection,
	useDisclosure,
	useTableOptions,
	useTitle,
} from "@msuite/picasso";
import { type FC, Fragment, useMemo, useState } from "react";
import { useParams } from "react-router";
import { columns } from "./table-config";

export const Campaigns: FC = () => {
	/** State */
	const [answerState, setAnswerState] = useState<"yes" | "no" | "unanswered" | "all">("all");

	/** Hooks */
	useTitle("Kampagnen");
	const { isOpen, onClose, onOpen } = useDisclosure();
	const tableOptions = useTableOptions();
	const params = useParams();
	const campaignId = params.campaignId ?? undefined;
	const { data: unsorted } = useCollection<CampaignAnswer>(db, {
		path: `_campaigns/${campaignId}/answers`,
		subscribe: true,
	});

	const data = useMemo(() => {
		const sorted = unsorted.sort((a, b) => (b.answeredAt ?? "").localeCompare(a.answeredAt ?? ""));
		if (answerState === "yes") {
			return sorted.filter((answer) => answer.answer === "yes");
		}
		if (answerState === "no") {
			return sorted.filter((answer) => answer.answer === "no");
		}
		if (answerState === "unanswered") {
			return sorted.filter((answer) => answer.answer === undefined);
		}
		return sorted;
	}, [unsorted, answerState]);

	const itemCounts = useMemo(() => {
		return {
			all: unsorted.length,
			yes: data.filter((answer) => answer.answer === "yes").length,
			no: data.filter((answer) => answer.answer === "no").length,
			unanswered: data.filter((answer) => answer.answer === undefined).length,
		};
	}, [unsorted]);

	/** Render */
	return (
		<Fragment>
			<InnerSiteLayout>
				<HStack
					alignItems="baseline"
					spacing={3}
				>
					<HStack
						justifyContent="space-between"
						width="100%"
					>
						<VStack spacing={0}>
							<Heading>Kampagnen</Heading>
							<Label>ID: {campaignId}</Label>
						</VStack>
						<Button onClick={onOpen}>Person einladen</Button>
					</HStack>
				</HStack>
				<Input
					isSearch
					value={tableOptions.globalFilter}
					onChange={(e) => tableOptions.setGlobalFilter(e.target.value)}
					onClear={() => {
						tableOptions.setGlobalFilter("");
					}}
				/>
				<SegmentedControl
					key={unsorted?.length}
					options={[
						{ label: "Alle", value: "all", icon: itemCounts.all },
						{ label: "Zusagen", value: "yes", icon: itemCounts.yes },
						{ label: "Absagen", value: "no", icon: itemCounts.no },
						{
							label: "Unbeantwortet",
							value: "unanswered",
							icon: itemCounts.unanswered,
						},
					]}
					value={answerState}
					onChange={(e) => {
						setAnswerState(e);
					}}
				/>
				<StandardDataTable
					{...tableOptions}
					data={data}
					isLoading={false}
					columns={columns}
				/>
			</InnerSiteLayout>
			<AddCampaignInvitationModal
				isOpen={isOpen}
				onClose={onClose}
			/>
		</Fragment>
	);
};
