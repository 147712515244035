import {
	Button,
	Modal,
	ModalBody,
	ModalButtonGroup,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@msuite/picasso";
import { type FC, useState } from "react";

/** Props Interface */
interface ResendCampaignInvitationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => Promise<void>;
	email: string;
}

export const ResendCampaignInvitationModal: FC<ResendCampaignInvitationModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	email,
}) => {
	/** State */
	const [isLoading, setIsLoading] = useState<boolean>(false);

	/** Functions */
	async function handleOnConfirm() {
		try {
			setIsLoading(true);
			await onConfirm();
			onClose();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	/** Render */
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>E-Mail erneut versenden ?</ModalHeader>
				<ModalBody>
					<p>Möchten Sie die E-Mail erneut an "{email}" versenden?</p>
				</ModalBody>
				<ModalFooter>
					<ModalButtonGroup>
						<Button
							onClick={onClose}
							variant="ghost"
						>
							abbrechen
						</Button>
						<Button
							onClick={handleOnConfirm}
							isLoading={isLoading}
						>
							Erneut versenden
						</Button>
					</ModalButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
