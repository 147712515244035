import { db } from "@/core";
import type { ConstructionSiteContact, ProjectRequest } from "@msuite/katana";
import { Text, Tooltip, useDocument, useUIContext } from "@msuite/picasso";
import type { FC, MouseEvent } from "react";

/** Props Interface */
interface ProjectRequestClientProps {
	projectRequest: ProjectRequest | undefined;
}

export const ProjectRequestClient: FC<ProjectRequestClientProps> = ({ projectRequest }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const { data: client } = useDocument<ConstructionSiteContact>(db, {
		path: `_contactBook/${projectRequest?.clientId}`,
		queryClientOptions: {
			enabled: !!projectRequest?.clientId,
		},
	});

	/** Fallback */
	if (!projectRequest?.clientId) return <Text>{projectRequest?.clientName}</Text>;

	/** Functions */
	function handleOnOpenClient(e: MouseEvent<HTMLSpanElement>) {
		e.stopPropagation();
		window.open(`/contacts/${projectRequest?.clientId}`, "_blank");
	}

	/** Render */
	return (
		<Tooltip label="Auftraggeber öffnen">
			<Text
				onClick={handleOnOpenClient}
				noOfLines={1}
				color={colors.black}
			>
				{projectRequest?.clientId ? client?.name : projectRequest?.clientName}
			</Text>
		</Tooltip>
	);
};
