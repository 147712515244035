import { MentionInput } from "@/components/mention-input";
import { ax } from "@/core";
import type { ISiteManagersApiResponse } from "@msuite/katana";
import { FormControl, FormLabel, VStack, useApi } from "@msuite/picasso";
import type { FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { ProjectRequestNoteFormType } from "./schema";

export const ProjectRequestNoteForm: FC = () => {
	/** Context */
	const methods = useFormContext<ProjectRequestNoteFormType>();
	const [currentNote] = useWatch({
		control: methods.control,
		name: ["note"],
	});

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});

	/** Functions */
	function handleOnChange(text: string) {
		methods.setValue("note", text);
	}

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Notiz</FormLabel>
				<MentionInput
					value={currentNote ?? ""}
					onChange={handleOnChange}
					minHeight="20rem"
					items={
						data?.employees?.map((employee) => ({
							id: employee.id,
							display: `${employee.vorname} ${employee.nachname}`,
						})) ?? []
					}
				/>
			</FormControl>
		</VStack>
	);
};
