import { db } from "@/core";
import type { ProjectRequestTimelineEntry } from "@msuite/katana";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useTimelineSubscriber({
	projectRequestId,
	type,
}: {
	projectRequestId: string;
	type: ProjectRequestTimelineEntry["type"] | "all" | string;
}) {
	/** State */
	const [entries, setEntries] = useState<(ProjectRequestTimelineEntry & { id: string })[]>([]);

	/** Effects */
	useEffect(() => {
		function subscribe() {
			const collectionRef = collection(db, `projectRequests/${projectRequestId}/requestTimeline`);
			let q = query(collectionRef, orderBy("createdAt", "desc"));
			if (type !== "all") q = query(q, where("type", "==", type));
			return onSnapshot(q, (snapshot) => {
				const data = snapshot.docs.map(
					(doc) =>
						({
							...doc.data(),
							id: doc.id,
						}) as ProjectRequestTimelineEntry & { id: string },
				);
				setEntries(data);
			});
		}

		const unsubscribe = subscribe();
		return () => {
			unsubscribe();
		};
	}, [projectRequestId, type]);

	return { entries };
}
