import { useFloating } from "@floating-ui/react";
import { Box, Portal, VStack, useToken, useUIContext } from "@msuite/picasso";
import { type FC, Fragment, useEffect, useRef, useState } from "react";
import { Mention, MentionsInput, type SuggestionDataItem } from "react-mentions";
import { MentionSuggestion } from "./mention-suggestion";
import "./styles.css";

/** Props Interface */
interface MentionInputProps {
	value: string;
	onChange: (value: string) => void;
	items: Array<SuggestionDataItem>;
	trigger?: string;
	minHeight?: string;
}

export const MentionInput: FC<MentionInputProps> = ({
	value: _value,
	onChange,
	items,
	trigger = "@",
	minHeight,
}) => {
	/** State */
	const [value, setValue] = useState<string>(_value);

	/** Hooks */
	const inputRef = useRef<HTMLInputElement>(null);
	const { refs, floatingStyles } = useFloating();

	/** Context */
	const { colors } = useUIContext();

	/** Effects */
	useEffect(() => {
		onChange(value);
	}, [value]);

	/** Functions */
	function handleOnFocusInput() {
		inputRef.current?.focus();
	}

	const focusedBackgroundColor = useToken("colors", colors.grayLight);
	const mentionStyle = {
		color: "transparent",
		backgroundColor: "rgba(47, 112, 219, 0.2)",
	};

	/** Render */
	return (
		<Box
			padding={4}
			rounded="lg"
			backgroundColor={colors.whiteAdjusted}
			borderWidth={1}
			cursor="text"
			onClick={handleOnFocusInput}
			position="relative"
			zIndex={99999999999}
		>
			<MentionsInput
				value={value}
				onChange={(e) => setValue(e.target.value)}
				inputRef={inputRef}
				placeholder="Schreibe etwas und benutze das @-Symbol um andere Nutzer zu markieren."
				customSuggestionsContainer={(children) => (
					<Fragment>
						<div ref={refs.setReference} />
						<Portal>
							<Box
								position="relative"
								zIndex={999999999999999}
								ref={refs.setFloating}
								style={{
									...floatingStyles,
									padding: "10px",
								}}
							>
								<VStack
									width="15rem"
									maxHeight="20rem"
									overflowY="scroll"
									rounded="lg"
									borderWidth={1}
									boxShadow="sm"
									py={2}
									backgroundColor={colors.whiteAdjusted}
									position="relative"
								>
									{children}
								</VStack>
							</Box>
						</Portal>
					</Fragment>
				)}
				style={{
					minHeight,
					suggestions: {
						background: "transparent",
						item: {
							"&focused": {
								backgroundColor: focusedBackgroundColor,
							},
						},
					},
				}}
			>
				<Mention
					appendSpaceOnAdd
					renderSuggestion={MentionSuggestion}
					markup="@[__display__](userId:__id__)"
					displayTransform={(_, display) => {
						return `@${display}`;
					}}
					trigger={trigger}
					data={items}
					style={mentionStyle}
				/>
			</MentionsInput>
		</Box>
	);
};
