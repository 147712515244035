import { ax, db } from "@/core";
import { type ConfigurationFileSales, type ProjectRequest, moment, numbi } from "@msuite/katana";
import {
	Box,
	Divider,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	VStack,
	useAsyncModal,
	useDocument,
	useUIContext,
} from "@msuite/picasso";
import { type FC, Fragment } from "react";

/** Props Interface */
interface ProjectRequestStateDiagramItemProps {
	isFirst: boolean;
	isLast: boolean;
	column: ConfigurationFileSales["columns"][number];
	projectRequestId: string;
	referenceArray: string[];
}

export const ProjectRequestStateDiagramItem: FC<ProjectRequestStateDiagramItemProps> = ({
	isFirst,
	isLast,
	column,
	projectRequestId,
	referenceArray,
}) => {
	/** Context */
	const { colors } = useUIContext();

	/** Hooks */
	const asyncModal = useAsyncModal();
	const { data: projectRequest } = useDocument<ProjectRequest>(db, {
		path: `projectRequests/${projectRequestId}`,
		subscribe: false,
	});

	const isCurrent = projectRequest?.state === column.id;
	const thisStateIndex = referenceArray.indexOf(column.id);
	const currentStateIndex = referenceArray.indexOf(projectRequest?.state ?? ("" as any));
	const isHighlighted = thisStateIndex <= currentStateIndex;

	/** Functions */
	async function handleOnStateChange() {
		try {
			const isConfirmed = await asyncModal({
				headerText: "Status wechseln?",
				bodyText: "Möchten Sie den Status der Anfrage wirklich ändern?",
				confirmButtonText: "Status ändern",
				cancelButtonText: "abbrechen",
			});
			if (!isConfirmed) return;
			await ax.patch(`/v2/project-requests/${projectRequestId}/state?state=${column.id}`);
		} catch (error) {
			console.error(error);
		}
	}

	/** Render */
	return (
		<Popover
			trigger="hover"
			isLazy
		>
			<PopoverTrigger>
				<Box
					flex={1}
					backgroundColor={isHighlighted ? colors.emerald : colors.grayDark}
					px={3}
					py={1}
					clipPath={`polygon(${isLast ? "100%" : "97.5%"} 0%, 100% 50%, ${isLast ? "100%" : "97.5%"} 100%, 0% 100%, ${isFirst ? "0%" : "2.5%"} 50%, 0% 0%)`}
					className="project-request-state-diagram-item"
					cursor="pointer"
					position="relative"
					borderLeftRadius={isFirst ? "md" : "none"}
					borderRightRadius={isLast ? "md" : "none"}
					mx="-0.15rem"
					ml={isFirst ? 0 : "-0.15rem"}
					mr={isLast ? 0 : "-0.15rem"}
					_hover={{
						opacity: 0.75,
					}}
					onClick={!isCurrent ? handleOnStateChange : undefined}
				>
					<Text
						textTransform="uppercase"
						fontSize="xs"
						textAlign="center"
						fontWeight="700"
						color={isHighlighted ? colors.white : colors.black}
					>
						{column.name}
					</Text>
				</Box>
			</PopoverTrigger>
			<PopoverContent>
				<ItemPopover
					projectRequest={projectRequest}
					column={column}
					isCurrent={isCurrent}
				/>
			</PopoverContent>
		</Popover>
	);
};

/** Props Interface */
interface ItemPopoverProps {
	isCurrent: boolean;
	projectRequest: ProjectRequest | undefined;
	column: ConfigurationFileSales["columns"][number];
}

const ItemPopover: FC<ItemPopoverProps> = ({ isCurrent, projectRequest, column }) => {
	const totalMinutes = projectRequest?.stateTracking?.[column.id] ?? 0;
	const currentMinutes = Math.abs(moment().diff(projectRequest?.stateChangedAt, "minutes"));

	return (
		<PopoverBody p={-2}>
			<VStack spacing={0}>
				{isCurrent && (
					<Fragment>
						<Text m={2}>Hier seit {currentMinutes} Minuten</Text>
						<Divider />
					</Fragment>
				)}
				<Text m={2}>Gesamt: {numbi().convertMinutesToDays(totalMinutes)}</Text>
			</VStack>
		</PopoverBody>
	);
};
