import { DropzoneModalContext } from "@/context/dropzone-modal";
import { Box, ModalBody, VStack } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { Dropzone } from "./dropzone";
import { Files } from "./files";
import { UploadProgress } from "./upload-progress";

/** Props Interface */
interface BodyProps {
	headDecoration?: React.ReactNode;
	children?: React.ReactNode;
}

export const Body: FC<BodyProps> = ({ headDecoration }) => {
	/** Context */
	const { isUploading } = useContext(DropzoneModalContext);

	/** Render */
	return (
		<ModalBody>
			<VStack
				alignItems="stretch"
				spacing={6}
			>
				{headDecoration && headDecoration}
				<Dropzone />
				<Files />
				<Box>{isUploading && <UploadProgress />}</Box>
			</VStack>
		</ModalBody>
	);
};
