import { ax } from "@/core";
import type { ISiteManagersApiResponse, Offer, OfferInformation } from "@msuite/katana";
import { FormControl, FormLabel, Input, Select, VStack, useApi } from "@msuite/picasso";
import { Dropzone } from "components/upload";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";

/** Props Interface */
interface ScannedOfferFormProps {
	files: File[];
	onFilesChange: (files: File[]) => void;
	onRemoveFile: (fileIndex: number) => void;
}

export const ScannedOfferForm: FC<ScannedOfferFormProps> = ({
	files,
	onFilesChange,
	onRemoveFile,
}) => {
	/** Context */
	const methods = useFormContext<OfferInformation | Offer>();

	/** Hooks */
	const { data } = useApi<ISiteManagersApiResponse>(ax, {
		url: "/v2/employees/site-managers",
		dependencies: ["site-managers"],
	});

	/** Render */
	return (
		<VStack>
			<FormControl>
				<FormLabel>Angebotsnummer</FormLabel>
				<Input {...methods.register("offerNumber")} />
			</FormControl>
			<FormControl>
				<FormLabel>Angebotsdatum</FormLabel>
				<Input
					type="date"
					{...methods.register("offerDate")}
				/>
			</FormControl>
			<FormControl>
				<FormLabel>Projekt</FormLabel>
				<Input {...methods.register("projectName")} />
			</FormControl>
			<FormControl>
				<FormLabel>Firma</FormLabel>
				<Input {...methods.register("companyName")} />
			</FormControl>
			<FormControl>
				<FormLabel>Erstellt von</FormLabel>
				<Select
					{...methods.register("employeeId")}
					placeholder="Bitte auswählen"
					key={data?.employees?.length}
				>
					{data?.employees?.map((employee) => (
						<option
							key={employee.id}
							value={employee.id}
						>
							{employee.vorname} {employee.nachname}
						</option>
					))}
				</Select>
			</FormControl>

			<FormControl>
				<FormLabel>Kunde</FormLabel>
				<Input {...methods.register("customerName")} />
			</FormControl>
			<FormControl>
				<FormLabel>Weitere Dokumente</FormLabel>
				<Dropzone
					defaultFiles={files}
					onFilesChange={onFilesChange}
					onRemoveFile={onRemoveFile}
					accept={{
						"application/pdf": [".pdf"],
					}}
				/>
			</FormControl>
		</VStack>
	);
};
